import React, { Component } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
export class ModalConfirmPhoneNumber extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isBusy: false,
            errors: null,
            phoneNumber: '',
        }
    }

    ButtonCancel() {
        this.setState({ isBusy: false, errors: null });
        if (this.props.onCancel)
            this.props.onCancel();
    }

    ButtonConfirm() {
        this.setState({ isBusy: true, errors: null });
        if (this.props.onConfirm)
            this.props.onConfirm(this.state.phoneNumber);
    }

    render() {
        let errors = this.state.errors && this.state.errors ? this.state.errors.error : null;
        return (
            <Modal centered={this.props.device && this.props.device.ShowMobileUI()} show={this.props.show} onHide={() => this.props.onCancel ? this.ButtonCancel() : this.ButtonConfirm()}>

                <Modal.Header className={this.props.theme && this.props.theme.GetModalConfirmHeaderClassName()} data-bs-theme="dark">

                    <Modal.Title>A quick question</Modal.Title>

                </Modal.Header>

                <Modal.Body className={this.props.theme && this.props.theme.GetModalConfirmBodyClassName()}>

                    <p>You haven't provided us with a phone number. It's entirely optional, but it would help us to support you better during the study.</p>
                    <p>If you would rather not share your phone number with us just leave the field below blank and click Ok.</p>
                    <p>If you change your mind later, you can always add/remove your phone number by updating your profile using the <b><i>Account- Manage</i></b> link in the <b><i>Options</i></b> section (see bottom of page).</p>
                    <Form>
                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                            <Form.Label column sm="5">
                                Phone Number:
                            </Form.Label>
                            <Col sm="7">
                                <Form.Control
                                    maxLength="25"
                                    keyboardType="numeric"    
                                    defaultValue="" 
                                    onChange={(evt) => this.setState({ "phoneNumber": evt.target.value })} />
                            </Col>
                        </Form.Group>
                    </Form>

                </Modal.Body>

                <Modal.Footer className={this.props.theme && this.props.theme.GetModalConfirmBodyClassName()}>
                    <Button variant="primary" onClick={() => this.ButtonConfirm()}>
                        Ok
                    </Button>
                </Modal.Footer>

            </Modal>
        );
    }

}
