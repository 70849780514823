import React, { Component } from "react";
import { ApplicationPaths } from './api-authorization/ApiAuthorizationConstants';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Navbar, NavbarBrand } from 'reactstrap';
import { Link } from 'react-router-dom';
import Badge from 'react-bootstrap/Badge';

import './Footer.css';

export class Footer extends Component {
    static displayName = Footer.name;

    constructor(props) {
        super(props);

        this.state = {
            notifications: [],
        }
    }

    componentDidMount() {
        if (this.props.notifications && this.props.notifications.length > 0) {
            this.setState({ notifications: this.props.notifications }, () => { console.log("ALERTS BADGE SET", this.state.notifications) })
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.notifications !== prevProps.notifications) {
            if (this.props.notifications && this.props.notifications.length > 0)
                this.setState({ notifications: this.props.notifications }, () => { console.log("ALERTS BADGE SET", this.state.notifications) })
            else
                this.setState({ notifications: [] }, () => { console.log("ALERTS BADGE CLEARED", this.state.notifications) });
        }
    }

    render() {
        return (
            <footer className="footer-app fixed-bottom d-flex justify-content-center">
                <div className="footer-app-icons container">
                    <ul className="nav nav-bg d-flex justify-content-around ">
                        <li className="nav-item">
                            <div className="text-center">
                                <a className="nav-link" href="/Games">
                                    <img alt="Games" src={require(`../assets/games.svg`).default} className="app-footer-icon" />
                                </a>
                                <div>Games</div>
                            </div>
                        </li>
                        <li className="nav-item">
                            <div className="text-center">
                                <a className="nav-link" href="/Dashboard">
                                    <img alt="Tests" src={require(`../assets/pathway.svg`).default} className="app-footer-icon" />
                                </a>
                                <div>Pathway</div>
                            </div>
                        </li>
                        <li className="nav-item">
                            <div className="text-center">
                                <a className="nav-link" href="/MyDetails">
                                    <img alt="Options" src={require(`../assets/mydetails.svg`).default} className="app-footer-icon" />
                                </a>
                                <div>Options</div>
                            </div>
                        </li>
                        {this.state.notifications.length > 0 && (
                            <li className="nav-item">
                                <div className="text-center">
                                    <div>
                                        <a className="nav-link" href="/Alerts">
                                            <img alt="Options" src={require(`../assets/notifications.svg`).default} className="app-footer-icon" />
                                        </a>
                                        {this.state.notifications && this.state.notifications.length > 0 && (
                                            <Badge pill bg="danger" className="alert-badge">
                                                {this.state.notifications.length}
                                            </Badge>
                                        )}
                                    </div>
                                    <div>Alerts</div>
                                </div>
                            </li>
                        )}
                        {this.props.user && (this.props.user.IsUser() || this.props.user.IsAdmin() || this.props.user.IsDeveloper()) && (
                            <li className="nav-item">
                                <div className="text-center">
                                    <a className="nav-link" href="/DashboardAdmin">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-gear-fill" viewBox="0 0 16 16">
                                            <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z" />
                                        </svg>
                                    </a>
                                    <div>Admin</div>
                                </div>
                            </li>
                        )}
                    </ul>
                </div >
            </footer>
        );


    }

}

export class FooterLogin extends Component {
    static displayName = FooterLogin.name;

    constructor(props) {
        super(props);

        this.state = {
            showInstallModal: false,
        };

    }

    Install() {
        if (this.props.installHandler) {
            this.props.installHandler.prompt();
        }
    }

    navigateTo(url) {
        window.location.assign(url);
    }

    componentDidMount() {

    }

    render() {
        return (
            <div>
                <footer className="footer-app-login fixed-bottom pt-3">
                    <div className="container">
                        <div className="d-flex justify-content-between login-menu-home">
                            {this.props.device && this.props.device.ShowMobileUI() && (
                                <div className="w-100">
                                    <Button variant="info" className="login-button" onClick={() => this.setState({ showInstallModal: true })}>Install</Button>
                                </div>
                            )}
                            {this.props.device && this.props.device.ShowMobileUI() && (
                                <div>&nbsp;</div>
                            )}
                            <div className="w-100">
                                <Button variant="primary" className="login-button" onClick={() => this.navigateTo(`${ApplicationPaths.Register}`)}>Register</Button>
                            </div>
                            <div>&nbsp;</div>
                            <div className="w-100">
                                <Button variant="success" className="login-button" onClick={() => this.navigateTo(`${ ApplicationPaths.Login }`)}>Login</Button>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between ps-5 pe-5 pt-4 app-footer-links">
                            <a className="nav-link" href="/privacy">
                                <div>Privacy</div>
                            </a>
                            <a className="nav-link" href="/terms">
                                <div>Terms</div>
                            </a>
                            <a className="nav-link" href="/faq">
                                <div>FAQ</div>
                            </a>
                            <a className="nav-link" href="/contactDetails">
                                <div>Contact</div>
                            </a>
                        </div>
                        <div className="text-center ps-3 pe-3 pb-2">
                            <span className="app-copyright">&copy;{new Date().getFullYear()} - University of Exeter - {this.props.user.AppVersion()}</span>
                        </div>
                    </div>
                </footer>

                <Modal
                    show={this.state.showInstallModal}
                    onHide={() => this.InstallClose()}
                    fullscreen={true}
                    backdrop="static"
                    keyboard={false}
                    scrollable>
                    <Modal.Body className="p-0">

                        <header className="header-app fixed-top">
                            <div className="container">
                                <Navbar>
                                    <NavbarBrand tag={Link} to="/">
                                        <div className="logo-img">
                                            <img src={require(`../assets/logo.svg`).default} alt="Logo" />&nbsp;
                                        </div>
                                    </NavbarBrand>
                                    <div className="close-app-x">
                                        <a id="close" className="nav-link" href="/" title="Close">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="white" className="bi bi-x" viewBox="0 0 16 16">
                                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                                            </svg>
                                        </a>
                                    </div>
                                </Navbar>
                            </div>
                        </header>

                        <div className="install-page">
                            <h1>Install</h1>
                            <p>This website works best when installed on your home screen.</p>
                            {this.RenderInstallPwaNotice()}
                        </div>

                    </Modal.Body>

                </Modal>
            </div>
        );
    }

    RenderInstallPwaNotice() {
        if (this.props.installHandler) {
            if (this.props.device && this.props.device.IsInstalledPWA()) {

                return (

                    <div>
                        <p>It looks as if you are on a device that supports automatic installation and you appear to have already installed BEACON.</p>
                        <p>If you want to re-install BEACON then you will need to delete the BEACON icon on your homescreen and come back to this page.</p>
                    </div>

                );

            } else {

                return (

                    <div>
                        <p>It looks as if you are on a device that supports automatic installation. In which case you can use the button below to start the installation process.</p>
                        <p>Once installed you should have a new BEACON icon on your homescreen.</p>
                        <div className="w-100 text-center">
                            <Button variant="primary" onClick={() => this.Install()}>Install</Button>
                        </div>
                    </div>

                );

            }
        }
        else {
            return (

                <div>
                    <h3>If you are on a device that supports automatic installation, you may be able to use the button below to start the installation process.</h3>
                    <div className="d-flex justify-content-between p-2">
                        <div>Once installed you should have a new BEACON icon on your homescreen.</div>
                        <Button variant="primary" onClick={() => this.Install()}>Install</Button>
                    </div>
                    <hr/>
                    <h3>If you are NOT on a device that supports automatic installation, you will need to add this website to your home screen.</h3>
                    <p>On IOS devices:</p>
                    <ol>
                        <li><b>Click on the "Share" option (usually an icon at the top right of your screen that looks like this: <i className="bi bi-box-arrow-up"></i>).</b></li>
                        <li><b>Find the option to "Save to Home Screen" (usually right at the bottom of the share options).</b></li>
                    </ol>

                    <p>On Android devices:</p>
                    <ol>
                        <li><b>Click on the "Install" option (usually an icon at the top right of your screen. You may need to click on the "more" ellipsis "..." first.</b></li>
                        <li><b>Follow the automatic install options.</b></li>
                    </ol>

                    <p><i>Note: If you have opened BEACON from an icon on your home screen then you will not see a "share" option, or any other web browser menus. If you want to re-install BEACON then you will need to delete the BEACON icon on your homescreen and come back to this page.</i></p>
                </div>

            );
        }
    }

}