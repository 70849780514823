import React, { Component } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { Format } from './shared/GlobalFns';

import './Alerts.css'

export class Alerts extends Component {
    static displayName = Alerts.name;

    constructor(props) {
        super(props);

        this.state = {
            notifications: [],
        }

        this.Format = new Format();
    }

    componentDidMount() {
        if (this.props.notifications && this.props.notifications.length > 0) {
            this.setState({ notifications: this.props.notifications })
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.notifications !== prevProps.notifications) {
            if (this.props.notifications && this.props.notifications.length > 0)
                this.setState({ notifications: this.props.notifications })
            else
                this.setState({ notifications: [] });
        }
    }

    render() {
        return (
            <div className="flex-fill">
                <h1>Alerts</h1>

                {this.state.notifications.length === 0 && (
                    <div>
                        <p>At the moment we don't have anything to display here, but as soon as we have anything we will let you know.</p>
                        <p>We will also email you the same notifications that are shown here.</p>
                    </div>
                )}

                {this.state.notifications.length > 0 && (
                    <div>
                        <p>This is a reminder of your most recent notifications, in case you did not receive an email.</p>
                        <Accordion
                            className="accordian-dashboard"
                            defaultActiveKey="CARD-0">

                            {this.state.notifications.length > 0 && (
                                this.state.notifications.map((comm, index) => {
                                    return (
                                        <Accordion.Item
                                            key={`KEY-${index}`}
                                            className="accordian-item-dashboard w-100 mb-2"
                                            eventKey={`CARD-${index}`}>

                                            <Accordion.Header className="accordian-header-dashboard">
                                                <div className="w-100">
                                                    <div className="d-flex justify-content-between">
                                                        <h5>{comm.subject}</h5>
                                                        <div>{this.Format.FormatDate(comm.date)}</div>
                                                    </div>
                                                </div>
                                            </Accordion.Header>
                                            <Accordion.Body className="accordian-body-dashboard ps-4 pe-4">
                                                <p dangerouslySetInnerHTML={{ __html: comm.message }}></p>
                                            </Accordion.Body>

                                        </Accordion.Item>
                                    )
                                })
                            )}

                        </Accordion>
                    </div>
                )}

            </div>
        );
    }

}
