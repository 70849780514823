import React, { Component } from 'react';
import Table from 'react-bootstrap/Table';

export class GameAwardsByScore extends Component {

    constructor(props) {
        super(props);

        this.state = {
            brainTrainingLevels: [
                { text: "EASY", award: "", awardText: "", value: 1, className: "text-nowrap btn btn-sm btn-success rounded-pill white" },
                { text: "MEDIUM", award: "BRONZE", awardText: "Great<br/>Going", value: 300, className: "text-nowrap btn btn-sm btn-info rounded-pill white" },
                { text: "HARD", award: "SILVER", awardText: "Top<br/>Tier", value: 500, className: "text-nowrap btn btn-sm btn-warning rounded-pill white" },
                { text: "DIFFICULT", award: "GOLD", awardText: "Genius<br/>Level", value: 750, className: "text-nowrap btn btn-sm btn-warning rounded-pill white" },
                { text: "IMPOSSIBLE", award: "PLATINUM", awardText: "WOW!!!", value: 900, className: "text-nowrap btn btn-sm btn-danger rounded-pill white" }
            ],
        }
    }

    IsEmpty(value) {
        return (!value || /^\s*$/.test(value));
    }

    HasValidResults() {
        return this.props.resultsSummary && this.props.resultsSummary.passedCount > 0;
    }

    render() {
        let awardEntries = [];
        if (this.HasValidResults()) {

            let award = null;
            let awardText = '';
            let summary = this.props.resultsSummary;

            let maxLevelsReached = this.state.brainTrainingLevels.filter(l => l.value <= summary.maxComplexity && !this.IsEmpty(l.award));
            let maxLevelsNotReached = this.state.brainTrainingLevels.filter(l => l.value > summary.maxComplexity && !this.IsEmpty(l.award));

            if (maxLevelsReached.length > 0) {
                let maxLevel = maxLevelsReached[maxLevelsReached.length - 1];
                if (summary.previousComplexity < summary.mostRecentComplexity && summary.mostRecentComplexity < maxLevel.value && !this.IsEmpty(maxLevel.award)) {
                    award = maxLevel.award;
                    awardText = maxLevel.awardText;
                }
            }

            if (!award && summary.passedCount === 1 && summary.maxComplexity > 0) {
                award = 'FIRSTGAME';
                awardText = "Game<br/>On!";
            }

            if (award) {

                // Presentation
                awardEntries.push(

                    this.RenderAward(award, awardText, "award-presentation", true)

                );

            } else {

                // History
                awardEntries.push(

                    <div key="AWARDS-WON" className={this.props.theme.GetTextBootstrapContrastVariant()}>
                        <h2>Awards Won</h2>
                        <Table className="transparent-table noEndBorder" variant={this.props.theme.GetPlayBackgroundVariant()}>
                            <tbody>
                                <tr>
                                    <td className="award-icon-box">
                                        {this.RenderAwardImage("FIRSTGAME", "award-icon")}
                                    </td>
                                    {maxLevelsReached && maxLevelsReached.length > 0 && this.RenderAwardsWon(maxLevelsReached)}
                                    {this.RenderAwardsNotWon(maxLevelsNotReached)}
                                </tr>
                            </tbody>
                        </Table>
                    </div>

                );
            }

        }
        return awardEntries;
    }

    RenderAwardsWon(maxLevelsReached) {
        let awardsList = [];
        maxLevelsReached.forEach((level, index) => {
            awardsList.push(
                <td key={`AWARD-WON-${index}`} className="award-icon-box">
                    {this.RenderAwardImage(level.award, "award-icon")}
                </td>
            );
        })
        return awardsList;
    }

    RenderAwardsNotWon(maxLevelsNotReached) {
        let awardsList = [];
        maxLevelsNotReached.forEach((level, index) => {
            awardsList.push(
                <td key={`AWARD-NOTWON-${index}`} className="award-icon-box">
                    {this.RenderAwardImage("UNKNOWN", "award-icon")}
                </td>
            );
        })
        return awardsList;
    }

    RenderAward(award, awardText, className) {
        let text = awardText;
        let img = this.RenderAwardImage(award, className);

        return (
            <div key="AWARD-PRESENTED" className={`d-flex p-3 justify-content-center ${this.props.theme.GetTextBootstrapContrastVariant()}`}>
                {img && <div className="ms-auto">{img}</div>}
                {text && (<div className="ps-4 veryBigLabel me-auto my-auto"><span dangerouslySetInnerHTML={{ __html: text }}></span></div>)}
            </div>
        )
    }

    RenderAwardImage(award, className) {
        let img = null;
        switch (award) {
            case "FIRSTGAME":
                img = (<img alt={award} src={require(`../../../assets/Awards/FirstGame.svg`).default} className={className} />);
                break;
            case "BRONZE":
                img = (<img alt={award} src={require(`../../../assets/Awards/Bronze.svg`).default} className={className} />);
                break;
            case "SILVER":
                img = (<img alt={award} src={require(`../../../assets/Awards/Silver.svg`).default} className={className} />);
                break;
            case "GOLD":
                img = (<img alt={award} src={require(`../../../assets/Awards/Gold.svg`).default} className={className} />);
                break;
            case "PLATINUM":
                img = (<img alt={award} src={require(`../../../assets/Awards/Platinum.svg`).default} className={className} />);
                break;
            case "UNKNOWN":
                img = (<img alt={award} src={require(`../../../assets/Awards/Unknown.svg`).default} className={className} />);
                break;
            default:
                break;
        }
        return img;
    }
}
