import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu, NavMenuPages } from './NavMenu';
import Alert from 'react-bootstrap/Alert';
import { Footer, FooterLogin } from "./Footer";
import { SideBar } from "./SideBar";

import './Layout.css';

export class Layout extends Component {
    static displayName = Layout.name;

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState) {

    }

    render() {
        return (
            <div className="main-app-background">
                <div className="container">
                    <NavMenu user={this.props.user} device={this.props.device} theme={this.props.theme} />
                    <Container tag="main" className="main-app d-flex justify-content-start">
                        <div className="w-100 app-page">
                            {this.props.children}
                        </div>
                    </Container>
                    <Footer
                        user={this.props.user}
                        theme={this.props.theme}
                        device={this.props.device}
                        flag={this.props.flag}
                        notifications={this.props.notifications}
                    />
                </div>
            </div>
        );
    }
}

export class LayoutAuth extends Component {
    static displayName = Layout.name;

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState) {

    }

    render() {
        return (
            <div className="main-app-background">
                <div className="container">
                    <NavMenu user={this.props.user} device={this.props.device} theme={this.props.theme} />
                    <Container tag="main" className="main-app d-flex justify-content-start">
                        <div className="w-100 app-page">
                            {this.props.children}
                        </div>
                    </Container>
                </div>
            </div>
        );
    }
}

export class LayoutPages extends Component {
    static displayName = LayoutPages.name;

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState) {

    }

    render() {
        return (
            <div className="additional-page-app-background">
                <div className="container">
                    <NavMenuPages user={this.props.user} device={this.props.device} theme={this.props.theme} />
                    <Container tag="main" className="main-app d-flex justify-content-start">
                        <div className="w-100 app-page">
                            {this.props.children}
                        </div>
                    </Container>
                </div>
            </div>
        );
    }
}

export class LayoutAdmin extends Component {
    static displayName = LayoutAdmin.name;

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState) {

    }

    render() {

        if (this.props.device.IsPhone()) {

            return (
                <div className="flex-fill main-app-background-admin">
                    <div className="container">
                        <NavMenu user={this.props.user} device={this.props.device} theme={this.props.theme} />
                        <Container tag="main" className="main-app">
                            <SideBar user={this.props.user} device={this.props.device} theme={this.props.theme} />
                            <div className="w-100 app-page">
                                {this.props.children}
                            </div>
                        </Container>
                        <Footer
                            user={this.props.user}
                            theme={this.props.theme}
                            device={this.props.device}
                            flag={this.props.flag}
                            notifications={this.props.notifications}
                        />
                    </div>
                </div>
            );

        } else {

            return (
                <div className="flex-fill main-app-background-admin">
                    <div className="container">
                        <NavMenu user={this.props.user} device={this.props.device} theme={this.props.theme} />
                        <Container tag="main" className="main-app d-flex justify-content-start">
                            <SideBar user={this.props.user} device={this.props.device} theme={this.props.theme} />
                            <div className="w-100 app-page">
                                {this.props.children}
                            </div>
                        </Container>
                        <Footer
                            user={this.props.user}
                            theme={this.props.theme}
                            device={this.props.device}
                            flag={this.props.flag}
                            notifications={this.props.notifications}
                        />
                    </div>
                </div>
            );

        }
    }
}

export class LayoutLogin extends Component {
    static displayName = LayoutAdmin.name;

    ConsentToCookie() {
        if (this.props.user)
            this.props.user.SetConsentCookie();
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState) {

    }

    render() {
        return (
            <div className="main-app-background">
                {this.props.children}
                <FooterLogin
                    installHandler={this.props.installHandler}
                    user={this.props.user}
                    theme={this.props.theme}
                    device={this.props.device}
                    flag={this.props.flag}
                />
            </div>
        );
    }

    RenderCookieAlert() {
        if (this.props.user && this.props.user.ConsentCookie() && this.props.user.ConsentCookie().show && !this.props.user.IsLoggedIn())
            return (
                <Alert className="p-3 pe-5 m-1" variant="info" onClose={() => this.ConsentToCookie()} dismissible>
                    We only use cookies to keep you logged in and to manage your session.
                </Alert>
            );
    }

}
