import React, { Component } from 'react';
import { StudyApi, AccountApi } from './shared/BEACONApi';
import { PlayQuestionnaireModal } from './Questionnaire/PlayQuestionnaireModal';
import { PlayBatteryModal } from './Battery/PlayBatteryModal';
import { Format, Validate } from './shared/GlobalFns';
import { ActivityList } from './Activities/ActivityList'
import { ModalConfirm } from './shared/ModalConfirm'
import { ModalConfirmPhoneNumber } from './shared/ModalConfirmPhoneNumber'
import update from 'immutability-helper';

import './Dashboard.css'

export class Dashboard extends Component {
    static displayName = Dashboard.name;

    constructor(props) {
        super(props);

        this.state = {
            study: null,
            schedule: null,
            isBusy: true,
            selectedActivity: null,
            showModal: {
                playQuestionnaire: false,
                playBattery: false,
                cannotOpenActivity: false,
            },
            scrollPosition: 0,
            cannotOpenTitle: "BEACON",
            cannotOpenMessage: "You cannot do this activity yet.",
            confirmPhoneNumber: true,
        }

        this.activityList = React.createRef();
        this.cannotOpenActivity = React.createRef();

        this.StudyApi = new StudyApi();
        this.AccountApi = new AccountApi();
        this.Format = new Format();
        this.Validate = new Validate();
    }

    GetData() {

        this.setState({ isBusy: true }, () => {
            if (this.props.getSchedule) {

                this.props.getSchedule((data) => {
                    this.setState({ schedule: data, isBusy: false }, () => {

                        if (this.state.scrollPosition !== window.scrollY)
                            window.scrollTo(
                                {
                                    top: this.state.scrollPosition,
                                    left: 0,
                                    behavior: "instant",
                                }
                            );

                    });

                }, (errors) => {

                    this.setState({ isBusy: false });

                });

            } else {
                this.setState({ isBusy: false });
            }
        });

    }

    OpenActivity(activity) {
        //console.debug("OPEN ACTIVITY", activity);
        switch (activity.status) {
            case "OPEN":
            case "ACTIVE":
            case "PASSED":
            case "FAILED":
            case "COMPLETED":
                switch (activity.type) {
                    case "QUESTIONNAIRE":
                        this.PlayQuestionnaire(activity);
                        break;
                    case "BATTERY":
                        this.PlayBattery(activity);
                        break;
                    case "PACKAGE":
                        window.location.href = "/games";
                        break;
                    default:
                        this.OpenCannotOpenActivityConfirm("Study Activity", "This activity is not recognised.");
                        break;
                }
                break;
            case "EXPIRED":
                this.OpenCannotOpenActivityConfirm("Study Activity", "This activity has expired.");
                break;
            default:
                this.OpenCannotOpenActivityConfirm("Study Activity", "Please complete the open activities first and we will open this one as soon as it becomes available.");
                break;
        }
    }

    PlayQuestionnaire(activity) {
        this.setState({ scrollPosition: window.scrollY, showModal: { playQuestionnaire: true }, selectedActivity: activity })
    }

    PlayQuestionnaireClose() {
        this.setState({ showModal: { playQuestionnaire: false }, selectedActivity: null })
        this.GetData();
    }

    PlayBattery(activity) {
        this.setState({ scrollPosition: window.scrollY, showModal: { playBattery: true }, selectedActivity: activity })
    }

    PlayBatteryClose() {
        this.setState({ showModal: { playBattery: false }, selectedActivity: null })
        this.GetData();
    }

    StageIsActiveOrOpen(stage) {
        return stage.status === "ACTIVE" ||
            stage.status === "OPEN";
    }

    OpenCannotOpenActivityConfirm(title, message) {
        let updated = update(this.state.showModal, { cannotOpenActivity: { $set: true } });
        this.setState({ showModal: updated, cannotOpenTitle: title, cannotOpenMessage: message });
    }

    CloseCannotOpenActivityConfirm() {
        let updated = update(this.state.showModal, { cannotOpenActivity: { $set: false } });
        this.setState({ showModal: updated });
    }

    RefreshSchedule() {
        // TODO: See if we can refresh the page when a user is in the middle of an activity without ending the activity.
        //if (this.props.getSchedule)
        //    this.props.getSchedule();
    }

    LoadPage() {
        this.setState({ schedule: this.props.schedule, isBusy: false }, () => {

            if (this.state.scrollPosition === 0 && this.activityList.current) {
                //console.debug("OPEN SCHEDULE", this.state.schedule);
                this.activityList.current.ScrollToTopOfOpenStage();
            }

        });
    }

    SetPhoneNumber(value) {
        this.AccountApi.UpdatePhoneNumber(value, () => {
            this.setState({ confirmPhoneNumber: false });
        }, (errors) => {
            console.log(errors);
        })
    }
    
    componentDidMount() {
        this.LoadPage();
    }

    componentDidUpdate(prevProps) {
        if (this.props.schedule !== prevProps.schedule)
            this.LoadPage();
    }

    render() {
        if (this.state.isBusy) {

            return (
                <div></div>
            )

        } else if (this.state.schedule) {

            return (
                <div className="flex-fill">

                    {this.renderHeaderSection()}

                    <ActivityList
                        ref={this.activityList}
                        schedule={this.props.schedule}
                        dashboardType={this.props.dashboardType}
                        owner="PARTICIPANT"
                        onSelectActivity={(activity) => this.OpenActivity(activity)}
                        onTimerCompleted={() => { this.RefreshSchedule() }}
                        user={this.props.user}
                        theme={this.props.theme}
                        device={this.props.device}
                    />

                    <PlayQuestionnaireModal
                        show={this.state.showModal.playQuestionnaire}
                        activity={this.state.selectedActivity}
                        user={this.props.user}
                        theme={this.props.theme}
                        device={this.props.device}
                        onHide={() => this.PlayQuestionnaireClose()}
                    />

                    <PlayBatteryModal
                        show={this.state.showModal.playBattery}
                        activity={this.state.selectedActivity}
                        user={this.props.user}
                        theme={this.props.theme}
                        device={this.props.device}
                        onHide={() => this.PlayBatteryClose()}
                    />

                    <ModalConfirm
                        ref={this.cannotOpenActivity}
                        show={this.state.showModal.cannotOpenActivity}
                        onConfirm={() => this.CloseCannotOpenActivityConfirm()}
                        title={this.state.cannotOpenTitle}
                        message={this.state.cannotOpenMessage}
                        device={this.props.device}
                        user={this.props.user}
                        theme={this.props.theme}
                    />

                    <ModalConfirmPhoneNumber
                        show={this.state.confirmPhoneNumber && !this.props.user.PhoneNumber() && !this.props.user.PhoneNumberConfirmed()}
                        onConfirm={(phoneNumber) => this.SetPhoneNumber(phoneNumber)}
                        onCancel={() => this.SetPhoneNumber()}
                        device={this.props.device}
                        user={this.props.user}
                        theme={this.props.theme}
                    />
                </div>
            );
        }
    }

    renderHeaderSection() {
        switch (this.props.dashboardType) {
            case "ONCE":
                let stageCount = this.props.schedule.stages.filter(st => st.availability === "ONCE").length;
                if (stageCount === 1) {
                    return (

                        <div className="dashboard-item">
                            <div className="pathway-welcome">
                                <h1>Welcome to your BEACON pathway!</h1>
                                <p>Here you can see upcoming events over the course of the study. Week by week we'll highlight your goals, achievements and key assessments along the way.</p>
                                <p>You can use the menu at the bottom of the screen to play games as soon as you have completed your baseline assessments, or use the suggested links along your journey.</p>
                            </div>
                        </div>

                    );
                }
            default:
                return (
                    <div></div>
                );
        }
    }
}
