import React, { Component } from "react";
import update from 'immutability-helper';
import { BrainTrainingModal } from '../shared/CognitiveItem/BrainTrainingModal';
import { GameProgress } from '../shared/CognitiveItem/GameProgress';
import { PackageGamesList } from './PackageGamesList';
import { CognitiveItemsApi, BatteryApi } from '../shared/CTAGApi';
import { Format } from '../shared/GlobalFns';

export class PlayPackageForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            battery: {
                id: -1,
                code: '',
                version: 1,
                language: '',
                text: {
                    text: '',
                    description: '',
                },
                dataPoints: [],
            },
            showModal: {
                confirm: false,
                play: false,
            },
            batteryIndex: -1,
            resultDataQuery: {
                pageNumber: 1,
                itemsInPage: 5,
                sortingCol: "ModifiedDate",
                sortType: "DESC",
                filter: "",
            },
            resultsSummary: null,
            cognitiveItemSummaries: null,
            selectedGame: null,
        };

        this.cognitiveItemsApi = new CognitiveItemsApi();
        this.batteryApi = new BatteryApi();

        this.format = new Format();
    }

    async GetBattery(code, version) {

        await this.batteryApi.GetByCode(code, version, (data) => {

            this.setState({ battery: data });

        }, (error) => {

        })

    }

    async GetCognitiveItem(code, version, category, callBackFn) {

        await this.cognitiveItemsApi.GetByCode(code, version, category, (data) => {

            this.setState({ selectedGame: data }, () => {

                if (callBackFn)
                    callBackFn();

            });

        }, (errors) => {

        })

    }

    async GetParticipantResults(battery, callBackFn) {

        // For packages there are no individual "battery results" (results are saved individually) we do this to get the summary
        await this.batteryApi.GetParticipantResults(this.state.resultDataQuery, battery, (data) => {

            let updatedDataQuery = update(data.query, { filter: { $set: this.state.resultDataQuery.filter }, totalCount: { $set: data.totalCount } });
            this.setState({
                //participantResults: data.data,
                //selectedInstance: (data.data && data.data.length > 0 ? -1 : 0),
                resultsSummary: data.summary,
                updatedDataQuery: updatedDataQuery,
                cognitiveItemSummaries: data.cognitiveItemSummaries,
            }, () => {
                if (callBackFn)
                    callBackFn();
            });

        }, (errors) => {

        });

    }

    SavedCognitiveItemResults(cognitiveItemResults) {
        this.GetParticipantResults(this.props.item, () => {
            this.GetBattery(this.props.item.code, this.props.item.version)
            //if (this.props.onSavedBatteryResults)
            //    this.props.onSavedBatteryResults(null);
        });
    }

    IsPlacebo() {
        return this.props.mode === "PLACEBO";
    }

    PlayOpen(game) {
        if (game) {
            this.GetCognitiveItem(game.code, game.version, game.category, () => {

                let updatedShowModal = update(this.state.showModal, { play: { $set: true } });
                this.setState({ showModal: updatedShowModal });

            })
        }
    }

    PlayClose() {
        let updatedShowModal = update(this.state.showModal, { play: { $set: false } });
        this.setState({ showModal: updatedShowModal });
    }

    componentDidMount() {
        this.setState({ batteryIndex: this.props.index });
        if (this.props.item)
            this.GetParticipantResults(this.props.item, () => {
                this.GetBattery(this.props.item.code, this.props.item.version)
            });
    }

    componentDidUpdate(prevProps) {
        if (this.props.item && this.props.item.code !== prevProps.item.code && this.props.item.version !== prevProps.item.version)
            this.GetParticipantResults(this.props.item, () => {
                this.GetBattery(this.props.item.code, this.props.item.version)
            });
    }

    render() {
        return (
            <div className={`${this.props.theme.GetTextBootstrapContrastVariant()}`}>
                {this.state.battery && this.state.resultsSummary && (
                    <GameProgress
                        timePlayedToday={this.state.resultsSummary.timePlayedToday}
                        targetMinutesDay={this.state.battery.targetMinutesDay}
                        timePlayedLast7Days={this.state.resultsSummary.timePlayedLast7Days}
                        targetMinutes7Days={this.state.battery.targetMinutes7Days}
                        timePlayedLast30Days={this.state.resultsSummary.timePlayedLast30Days}
                        maxComplexity={this.state.resultsSummary.maxComplexity}
                        targetMaxMinutesDay={this.state.battery.maxMinutesDay}
                        user={this.props.user}
                        device={this.props.device}
                        theme={this.props.theme}
                        showTargetProgress={true}
                        isPlacebo={this.IsPlacebo()}
                    />
                )}

                <PackageGamesList
                    battery={this.state.battery}
                    cognitiveItemSummaries={this.state.cognitiveItemSummaries}
                    device={this.props.device}
                    user={this.props.user}
                    theme={this.props.theme}
                    onGameSelected={(game) => this.PlayOpen(game)}
                />

                {this.state.showModal.play && (
                    <BrainTrainingModal
                        show={this.state.showModal.play}

                        cognitiveItemType="BRAINTRAINING"
                        code={this.state.selectedGame.code}
                        version={this.state.selectedGame.version}
                        category={this.state.selectedGame.category}
                        
                        inputType={this.props.inputType}
                        mode={this.props.mode}

                        languageCode="en-GB"
                        showTargetProgress={false}
                        isPlacebo={this.IsPlacebo()}

                        onSavedCognitiveItemResults={(cognitiveItemResults) => this.SavedCognitiveItemResults(cognitiveItemResults)}
                        onHide={() => this.PlayClose()}

                        device={this.props.device}
                        theme={this.props.theme}
                        user={this.props.user}
                    />
                )}

            </div>
        );
    }

}
