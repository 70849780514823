import React, { Component } from 'react';
import './FAQ.css'

export class FAQ extends Component {
    static displayName = FAQ.name;

    constructor(props) {
        super(props);

        this.state = {
        }
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps) {

    }

    render() {
        return (
            <div className="flex-fill">

                <h1>Frequently Asked Questions</h1>
                <h4>How do I take part in the study?</h4>
                <p>You can take part on your smartphone or computer. On the home page https://beaconstudy.org.uk/ click registration to complete the registration process and then the eligibility check. After completing the eligibility check, you will receive confirmation if you are eligible to take part.</p>
                <h4>What’s the eligibility criteria?</h4>
                <p>To be eligible for BEACON you:</p>
                <ul>
                    <li>Must be aged 18 or over</li>
                    <li>Have experienced brain fog or memory problems since having COVID-19</li>
                    <li>Have access to a computer/smartphone and the internet</li>
                    <li>Live in the UK</li>
                    <li>Speak English</li>
                </ul>
                <h4>Why am I not eligible?</h4>
                <p>You may not be eligible to take part in BEACON if:</p>
                <ul>
                    <li>You are currently taking part in another interventional clinical trial</li>
                    <li>Have accessed the ReaCT programme online through participation in the PROTECT UK ageing cohort at anytime during the last 12 months</li>
                    <li>Have a diagnosis of dementia</li>
                </ul>
                <h4>What can I expect if I decide to take part?</h4>
                <ul>
                    <li>Over 6-months, you will be asked to play brain training games on your computer or smartphone device as often as you wish.</li>
                    <li>We recommend playing at least 3 times per week for 10 minutes.</li>
                    <li>You will also be asked to complete a cognitive assessment and a series of questionnaires at the start of the study, at 6 weeks and then at 6 months (when the study finishes).</li>
                </ul>
                <h4>How long does this study take?</h4>
                <p>This is a 6-month clinical trial, which means your participation in the study will conclude after 6-months. However, after the trial concludes, you will still have access to the games.</p>
                <h4>When will I be able to start playing the games?</h4>
                <p>You will be able to start playing the games after completing the registration, enrolment questionnaires, the baseline assessment (Flame Brain Function Tests) and questionnaires.</p>
                <h4>How long do I have to play the games for?</h4>
                <p>As the study is a 6 month clinical trial, we ask participants to continue playing the games until the study concludes at the 6-month time point. We will let you know when you have reached the 6 month time point.</p>
                <h4>Why are the games not loading on my device?</h4>
                <p>We are aware of an issue where the games are not loading on some devices. We recommend trying different browsers and/or if possible, another device. The games are sometimes more likely to work on computers/laptops.</p>
                <h4>What’s the difference between the assessments and the games?</h4>
                <p>The cognitive assessment is a series of tests completed 3 times (at baseline, 6-weeks, and 6-months) throughout the study. The cognitive assessment is designed to assess your working memory and attention. The brain training games are available to play after the baseline assessment and questionnaires are completed and consists of 6 games that are approximately 3 minutes long. The games are designed to be challenging and fun and can be played as frequently as you wish. The cognitive assessment will expire if they have not been completed within the required time while the games will not expire.</p>
                <h4>I have completed the 6-week assessment, what do I do next?</h4>
                <p>After the completing the 6-week assessment and questionnaires, the goal is to continue playing the brain training games until the 6-month time point when you will be asked to complete another round of assessment and questionnaires.</p>
                <h4>How often do I need to play the games?</h4>
                <p>You can play the games as often as you like. We ask participants to play the games daily for 10 minutes and recommend playing for at least 3 times per week for at least 10 minutes. We will send you reminder emails to play the games. Please remember to take frequent breaks.</p>

            </div>
        );
    }
}
