import React, { Component } from 'react';
import { ModalConfirm } from '../ModalConfirm';
import { ButtonPlay } from '../Buttons/Play';
import { ButtonStatusResults } from '../Buttons/StatusResults';
import { TablePagination } from '../TablePagination';
import { PlayForm } from '../../CognitiveItem/PlayForm';
import { CognitiveTestSummary } from './CognitiveTestSummary'
import update from 'immutability-helper';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card';
import { Format } from '../GlobalFns'
import { CognitiveItemsApi } from '../CTAGApi'

export class CognitiveItemModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            fields: [
                { key: 'status', label: 'Status', className: 'hide-if-smaller-than-md' },
                { key: 'code', label: 'Name' },
                { key: 'version', label: 'Version', className: 'hide-if-smaller-than-sm' },
                { key: 'category', label: 'Category', className: 'hide-if-smaller-than-lg' },
                { key: 'inputType', label: 'Input Type', className: 'hide-if-smaller-than-xl' },
                { key: 'dataPoints', label: 'Data Points', className: 'hide-if-smaller-than-xxl' },
                { key: 'numberOfResults', label: 'Total Results', className: 'hide-if-smaller-than-xl' },
                { key: 'actions', label: '' },
            ],
            resultsFields: [
                { key: 'status', label: 'Status' },
                { key: 'startTime', label: 'Start Date' },
                { key: 'endTime', label: 'End Date' },
                { key: 'instance', label: 'Instance' },
                { key: 'actions', label: '' },
            ],
            showModal: {
                confirm: false,
                confirmDevDelete: false,
                confirmQuit: false,
                play: false,
                edit: false,
            },
            cognitiveItem: {},
            selectedInstance: -1, // -1 = List results (if available), 0 = start new session, >0 show results for that session
            dataQuery: {
                pageNumber: 1,
                itemsInPage: 10,
                sortingCol: "Code",
                sortType: "ASC",
                filter: "",
            },
            resultDataQuery: {
                pageNumber: 1,
                itemsInPage: 5,
                sortingCol: "ModifiedDate",
                sortType: "DESC",
                filter: "",
            },
            resultsSummary: null,
            selectedLanguage: 'en-GB',
            participantResults: [],
            header: null,
            brainTrainingLevels: [
                { text: "EASY", award: "", awardText: "", value: 1, className: "text-nowrap btn btn-sm btn-success rounded-pill white" },
                { text: "MEDIUM", award: "BRONZE", awardText: "Great<br/>Going", value: 300, className: "text-nowrap btn btn-sm btn-info rounded-pill white" },
                { text: "HARD", award: "SILVER", awardText: "Top<br/>Tier", value: 500, className: "text-nowrap btn btn-sm btn-warning rounded-pill white" },
                { text: "DIFFICULT", award: "GOLD", awardText: "Genius<br/>Level", value: 750, className: "text-nowrap btn btn-sm btn-warning rounded-pill white" },
                { text: "IMPOSSIBLE", award: "PLATINUM", awardText: "WOW!!!", value: 900, className: "text-nowrap btn btn-sm btn-danger rounded-pill white" }
            ],
        }

        this.cognitiveItemModalBody = React.createRef();
        this.playForm = React.createRef();

        this.format = new Format();

        this.cognitiveItemsApi = new CognitiveItemsApi();
    }

    async GetCognitiveItem(code, version, category, callBackFn) {

        await this.cognitiveItemsApi.GetByCode(code, version, category, (data) => {
            this.setState({ cognitiveItem: data }, () => {
                if (callBackFn)
                    callBackFn(this.state.cognitiveItem);
            });

        }, (errors) => {

        });

    }

    async GetParticipantResults(cognitiveItem, callBackFn) {

        await this.cognitiveItemsApi.GetParticipantResults(this.state.resultDataQuery, cognitiveItem, (data) => {

            let updatedDataQuery = update(data.query, { filter: { $set: this.state.resultDataQuery.filter }, totalCount: { $set: data.totalCount } });
            this.setState({
                participantResults: data.data,
                selectedInstance: (data.data && data.data.length > 0 ? -1 : 0),
                resultsSummary: data.summary,
                updatedDataQuery: updatedDataQuery,
            }, () => {
                if (callBackFn)
                    callBackFn();
            });

        }, (error) => {

        });

    }

    TabulateResultsData() {
        let rows = [];
        Array.prototype.forEach.call(this.state.participantResults, (data) => {
            let row = {};
            this.state.resultsFields.forEach((field) => {
                switch (field.key) {
                    case 'createdDate':
                    case 'modifiedDate':
                    case 'startTime':
                    case 'endTime':
                        row[field.key] = this.format.DateTime(data[field.key], this.state.selectedLanguage);
                        break;
                    case 'instance':
                        row[field.key] = data.cognitiveItem[field.key] ?? '-';
                        break;
                    case 'status':
                        row[field.key] = 'Status';
                        break;
                    case 'actions':
                        row[field.key] = 'Actions';
                        break;
                    default:
                        if (data[field.key])
                            row[field.key] = JSON.stringify(data[field.key]);
                        break;
                }
            });
            rows.push(row);
        });
        return rows;
    }

    SavedCognitiveItemResults(cognitiveItemResults, closePlayForm) {

        this.state.participantResults.push(cognitiveItemResults);

        if (closePlayForm)
            this.QuitCognitiveItem();

        if (this.props.onSavedCognitiveItemResults)
            this.props.onSavedCognitiveItemResults(cognitiveItemResults);
    }

    RefreshResults() {
        this.GetParticipantResults(this.state.cognitiveItem, () => {
            if (!this.state.participantResults || this.state.participantResults.length === 0)
                this.setState({ showModal: { play: false }, selectedInstance: -1, selectedComplexity: null, header: null });
            else
                this.setState({ selectedInstance: -1, selectedComplexity: null, header: null });
        });
    }

    PlayClose() {
        if (this.props.onHide)
            this.props.onHide();
        this.ResetPage();
    }

    ResetPage() {
        this.setState({
            participantResults: [],
            selectedInstance: -1,
            resultDataQuery: {
                pageNumber: 1,
                itemsInPage: 5,
                sortingCol: "ModifiedDate",
                sortType: "DESC",
                filter: "",
            },
            resultsSummary: null,
            header: null,
            totalCount: 0,
        });
    }

    PlaySelectInstance(instance, index) {
        this.setState({ selectedInstance: instance, selectedIndex: index }, () => {
            console.debug("PLAY", this.state.cognitiveItem, `INSTANCE = ${this.state.selectedInstance}`);
        });
    }

    UpdateHeader(header) {
        this.setState({ header: header })
    }

    QuitCognitiveItemOpen() {
        let updated = update(this.state.showModal, { confirmQuit: { $set: true } });
        this.setState({ showModal: updated })
    }

    QuitCognitiveItemClose() {
        let updated = update(this.state.showModal, { confirmQuit: { $set: false } });
        this.setState({ showModal: updated })
    }

    QuitCognitiveItem() {
        //console.debug("QuitCognitiveItem", this.state.participantResults)
        let updated = update(this.state.showModal, { confirmQuit: { $set: false } });
        if (!this.state.participantResults || (this.state.participantResults && this.state.participantResults.length === 0)) {
            this.setState({ showModal: updated }, () => {
                this.PlayClose();
            })
        } else {
            this.setState({ showModal: updated }, () => {
                this.RefreshResults();
            })
        }
    }

    SetSelectedComplexity(complexity) {
        this.setState({ selectedComplexity: complexity }, () => {
            //console.debug("SET COMPLEXITY", this.state.selectedComplexity);
        })
    }

    GetMostRecentEndComplexity() {
        if (this.state.selectedComplexity && this.state.selectedComplexity > 0) {
            //console.debug("DEFAULT COMPLEXITY (manual)", this.state.selectedComplexity);
            return this.state.selectedComplexity;
        } else {
            if (this.state.cognitiveItem.type === "BRAINTRAINING" && this.state.resultsSummary && this.state.resultsSummary.mostRecentComplexity) {
                //console.debug("DEFAULT COMPLEXITY (automatic)", this.state.resultsSummary.mostRecentComplexity);
                return this.state.resultsSummary.mostRecentComplexity;
            } else {
                return 1;
            }
        }
    }

    GetSelectedName() {
        if (this.state.cognitiveItem && this.state.cognitiveItem.text && this.state.cognitiveItem.text.text) {
            return this.state.cognitiveItem.text.text;
        } else if (this.state.cognitiveItem && this.state.cognitiveItem.code && this.state.cognitiveItem.version) {
            return `${this.state.cognitiveItem.code} v${this.state.cognitiveItem.version}`;
        } else {
            if (this.state.cognitiveItem.type === "COGNITIVETEST")
                return "Cognitive Test";
            else
                return "Brain Training Game";
        }
    }

    GetMaxComplexity() {
        if (this.state.resultsSummary)
            return this.state.resultsSummary.maxComplexity
        else
            return 0;
    }

    GetScore() {
        let score = this.state.header && this.state.header.score ? this.state.header.score : '';
        if (score !== "Score: 000")
            return score;
    }

    GetProgress() {
        return this.state.header && this.state.header.progress ? this.state.header.progress : '';
    }

    GetTime() {
        return this.state.header && this.state.header.time ? this.state.header.time : '';
    }

    HasValidResults() {
        return this.state.resultsSummary && this.state.resultsSummary.passedCount > 0;
    }

    HasAnyResults() {
        return this.state.resultsSummary !== null;
    }

    GetMostRecentErrorMessage() {
        let error = null;
        if (this.state.participantResults) {
            let notPassedResults = this.state.participantResults.filter(r => r.status !== "PASSED");
            if (notPassedResults.length > 0 && notPassedResults[0].exception)
                error = notPassedResults[0].exception;
        }
        return error;
    }

    LoadPage() {
        if (this.props.device.ShowMobileUI()) {

            this.GetCognitiveItem(this.props.code, this.props.version, this.props.category, (cognitiveItem) => {

                let updatedDataQuery = update(this.state.dataQuery, { itemsInPage: { $set: 0 } });
                this.setState({ dataQuery: updatedDataQuery }, () => {
                    //console.debug("LOAD", cognitiveItem);
                    this.GetParticipantResults(this.state.cognitiveItem);
                });

            })

        } else {

            this.GetCognitiveItem(this.props.code, this.props.version, this.props.category, (cognitiveItem) => {
                //console.debug("LOAD", this.state.cognitiveItem);
                this.GetParticipantResults(this.state.cognitiveItem);
            })

        }
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps) {

    }

    render() {
        return (
            <div>

                <Modal
                    show={this.props.show}
                    dialogClassName="cognitive-item-modal-test"
                    onHide={() => this.PlayClose()}
                    onEntered={() => this.LoadPage()}
                    fullscreen={true}
                    backdrop="static"
                    keyboard={false}
                    scrollable>

                    <Modal.Body className="p-0 cognitive-item-modal-body-test" ref={this.cognitiveItemModalBody}>
                        {this.RenderResultsOrCognitiveItem()}
                    </Modal.Body>

                </Modal>

                <ModalConfirm
                    ref={this.confirmQuitModal}
                    show={this.state.showModal.confirmQuit}
                    onCancel={() => this.QuitCognitiveItemClose()}
                    onConfirm={() => this.QuitCognitiveItem()}
                    title="Are you sure?"
                    message="If you leave now we will not be able to save any results and you will need to start again."
                    device={this.props.device}
                    user={this.props.user}
                    theme={this.props.theme}
                />

            </div>
        );
    }

    RenderResultsOrCognitiveItem() {
        if (this.state.cognitiveItem) {
            if (this.state.selectedInstance === -1) {
                return (

                    <div className="px-4 pt-2 pb-2 cognitive-item-summary">
                        <CognitiveTestSummary
                            cognitiveItem={this.state.cognitiveItem}
                            resultsSummary={this.state.resultsSummary}
                            totalCount={this.state.resultDataQuery.totalCount}
                            user={this.props.user}
                            device={this.props.device}
                            theme={this.props.theme}
                        />
                        {this.RenderTableOfResults()}
                    </div>

                );

            } else {
                return (

                    <PlayForm
                        ref={this.playForm}
                        cognitiveItemType="COGNITIVETEST"
                        
                        mode={this.props.mode}
                        inputType={this.props.inputType}

                        item={this.state.cognitiveItem}
                        instance={this.state.selectedInstance}
                        languageCode={this.state.selectedLanguage}
                        maxComplexity={this.GetMostRecentEndComplexity()}
                        defaultStartTime={new Date()}

                        onSavedCognitiveItemResults={(cognitiveItemResults, closePlayForm) => this.SavedCognitiveItemResults(cognitiveItemResults, closePlayForm)}
                        onUpdateHeader={(header) => this.UpdateHeader(header)}

                        user={this.props.user}
                        device={this.props.device}
                        theme={this.props.theme}

                        className="full-size"
                    />

                );

            }
        }
    }

    RenderTableOfResults() {
        let rows = this.TabulateResultsData();
        if (rows.length > 0) {
            if (this.props.device.ShowMobileUI()) {

                let additionalResults = this.state.resultDataQuery ? this.state.resultDataQuery.totalCount - this.state.resultDataQuery.itemsInPage : 0;
                return (
                    <div>
                        <h2>Recent Results</h2>
                        <Table responsive className="transparent-table" variant={this.props.theme.GetPlayBackgroundVariant()}>
                            <tbody>
                                {rows.map((_, index) => (
                                    <tr key={`RESULT-${index}`}>
                                        <Card as="td" className="clickable-card m-1">
                                            <Card.Body className="p-2" onClick={() => this.PlaySelectInstance(this.state.participantResults[index].cognitiveItem.instance, index)}>
                                                <Card.Text as="div">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div><span className="miniLabel">Completed:</span></div>
                                                            <div>{rows[index]["endTime"]}</div>
                                                        </div>
                                                        <div>
                                                            <ButtonStatusResults status={this.state.participantResults[index].status} />
                                                        </div>
                                                    </div>
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        {additionalResults > 0 && (
                            <p className="ps-2">NOTE: <i>{additionalResults} additional results not shown.</i></p>
                        )}
                    </div>
                );
            } else {
                return (
                    <div>
                        <h2>Results</h2>
                        <Table responsive className="noEndBorder transparent-table" variant={this.props.theme.GetPlayBackgroundVariant()}>
                            <thead>
                                <tr>
                                    <th colSpan={this.state.resultsFields.length} className="text-start text-nowrap">
                                        <TablePagination
                                            dataQuery={this.state.resultDataQuery}
                                            onUpdated={(query) => this.setState({ resultDataQuery: query }, () => { this.GetParticipantResults(this.state.cognitiveItem); })}
                                            user={this.props.user}
                                            device={this.props.device}
                                            theme={this.props.theme}
                                        />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {rows.map((_, index) => (
                                    <tr key={`RESULT-${index}`}>
                                        {this.state.resultsFields.map((item, subindex) => (
                                            rows[index][item.key] === 'Actions' ?
                                                this.ResultRowActions(index, subindex, this.state.participantResults[index].cognitiveItem.instance, this.props.user.Role()) :
                                                rows[index][item.key] === 'Status' ?
                                                    <td key={`STATUS-${subindex}`} className="pt-4">
                                                        <ButtonStatusResults status={this.state.participantResults[index].status} />
                                                    </td> :
                                                    <td key={`KEY-${subindex}`}>
                                                        <div><span className="miniLabel">{item.label}:</span></div>
                                                        <span dangerouslySetInnerHTML={{ __html: rows[index][item.key] }}></span>
                                                    </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                );
            }
        }
    }

    ResultRowActions(index, subindex, instance, role) {
        if (role === 'Developer' || role === 'Admin' || role === 'User') {

            return (
                <td key={`RESULTACTION-${subindex}`} className="text-end text-nowrap pt-4">
                    <ButtonPlay onClick={() => this.PlaySelectInstance(instance, index)} />
                </td>
            )

        } else {

            return (
                <td key={`RESULTACTION-${subindex}`} className="text-end text-nowrap pt-4">
                    <ButtonPlay onClick={() => this.PlaySelectInstance(instance, index)} />
                </td>
            )

        }
    }
}
