import React, { Component } from 'react';
import { ModalConfirm } from '../ModalConfirm';
import { ButtonBackApp } from '../Buttons/BackApp';
import { ButtonPlay } from '../Buttons/Play';
import { StatusSelect } from '../Inputs/StatusSelect';
import { ComplexitySelect } from '../Inputs/ComplexitySelect';
import { ButtonStatusResults } from '../Buttons/StatusResults';
import { TablePagination } from '../TablePagination';
import { PlayForm } from '../../CognitiveItem/PlayForm';
import { BrainTrainingSummary } from './BrainTrainingSummary'
import update from 'immutability-helper';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { ButtonMuteToggle } from '../Buttons/MuteToggle';
import { Format } from '../GlobalFns'
import { CognitiveItemsApi } from '../CTAGApi'
import { ButtonContinueGame } from '../Buttons/ContinueGame';

export class BrainTrainingModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            fields: [
                { key: 'status', label: 'Status', className: 'hide-if-smaller-than-md' },
                { key: 'code', label: 'Name' },
                { key: 'version', label: 'Version', className: 'hide-if-smaller-than-sm' },
                { key: 'category', label: 'Category', className: 'hide-if-smaller-than-lg' },
                { key: 'inputType', label: 'Input Type', className: 'hide-if-smaller-than-xl' },
                { key: 'dataPoints', label: 'Data Points', className: 'hide-if-smaller-than-xxl' },
                { key: 'numberOfResults', label: 'Total Results', className: 'hide-if-smaller-than-xl' },
                { key: 'actions', label: '' },
            ],
            resultsFields: [
                { key: 'status', label: 'Status' },
                { key: 'startTime', label: 'Start Date' },
                { key: 'endTime', label: 'End Date' },
                { key: 'instance', label: 'Instance' },
                { key: 'actions', label: '' },
            ],
            showModal: {
                confirm: false,
                confirmDevDelete: false,
                confirmQuit: false,
                play: false,
                edit: false,
            },
            cognitiveItem: {},
            selectedInstance: -1, // -1 = List results (if available), 0 = start new session, >0 show results for that session
            dataQuery: {
                pageNumber: 1,
                itemsInPage: 10,
                sortingCol: "Code",
                sortType: "ASC",
                filter: "",
            },
            resultDataQuery: {
                pageNumber: 1,
                itemsInPage: 5,
                sortingCol: "ModifiedDate",
                sortType: "DESC",
                filter: "",
            },
            resultsSummary: null,
            selectedLanguage: 'en-GB',
            participantResults: [],
            header: null,
            brainTrainingLevels: [
                { text: "EASY", award: "", awardText: "", value: 1, className: "text-nowrap btn btn-sm btn-success rounded-pill white" },
                { text: "MEDIUM", award: "BRONZE", awardText: "Great<br/>Going", value: 300, className: "text-nowrap btn btn-sm btn-info rounded-pill white" },
                { text: "HARD", award: "SILVER", awardText: "Top<br/>Tier", value: 500, className: "text-nowrap btn btn-sm btn-warning rounded-pill white" },
                { text: "DIFFICULT", award: "GOLD", awardText: "Genius<br/>Level", value: 750, className: "text-nowrap btn btn-sm btn-warning rounded-pill white" },
                { text: "IMPOSSIBLE", award: "PLATINUM", awardText: "WOW!!!", value: 900, className: "text-nowrap btn btn-sm btn-danger rounded-pill white" }
            ],
        }

        this.cognitiveItemModalBody = React.createRef();
        this.playForm = React.createRef();

        this.format = new Format();

        this.cognitiveItemsApi = new CognitiveItemsApi();
    }

    async GetCognitiveItem(code, version, category, callBackFn) {

        await this.cognitiveItemsApi.GetByCode(code, version, category, (data) => {
            this.setState({ cognitiveItem: data }, () => {
                if (callBackFn)
                    callBackFn(this.state.cognitiveItem);
            });

        }, (errors) => {

        });

    }

    async GetParticipantResults(cognitiveItem, callBackFn) {

        await this.cognitiveItemsApi.GetParticipantResults(this.state.resultDataQuery, cognitiveItem, (data) => {

            let updatedDataQuery = update(data.query, { filter: { $set: this.state.resultDataQuery.filter }, totalCount: { $set: data.totalCount } });
            this.setState({
                participantResults: data.data,
                selectedInstance: (data.data && data.data.length > 0 ? -1 : 0),
                resultsSummary: data.summary,
                updatedDataQuery: updatedDataQuery,
            }, () => {
                if (callBackFn)
                    callBackFn();
            });

        }, (error) => {

        });

    }

    TabulateResultsData() {
        let rows = [];
        Array.prototype.forEach.call(this.state.participantResults, (data) => {
            let row = {};
            this.state.resultsFields.forEach((field) => {
                switch (field.key) {
                    case 'createdDate':
                    case 'modifiedDate':
                    case 'startTime':
                    case 'endTime':
                        row[field.key] = this.format.DateTime(data[field.key], this.state.selectedLanguage);
                        break;
                    case 'instance':
                        row[field.key] = data.cognitiveItem[field.key] ?? '-';
                        break;
                    case 'status':
                        row[field.key] = 'Status';
                        break;
                    case 'actions':
                        row[field.key] = 'Actions';
                        break;
                    default:
                        if (data[field.key])
                            row[field.key] = JSON.stringify(data[field.key]);
                        break;
                }
            });
            rows.push(row);
        });
        return rows;
    }

    SavedCognitiveItemResults(cognitiveItemResults, closePlayForm) {

        this.state.participantResults.push(cognitiveItemResults);

        if (closePlayForm)
            this.QuitCognitiveItem();

        if (this.props.onSavedCognitiveItemResults)
            this.props.onSavedCognitiveItemResults(cognitiveItemResults);
    }

    RefreshResults() {
        this.GetParticipantResults(this.state.cognitiveItem, () => {
            if (!this.state.participantResults || this.state.participantResults.length === 0)
                this.setState({ showModal: { play: false }, selectedInstance: -1, selectedComplexity: null, header: null });
            else
                this.setState({ selectedInstance: -1, selectedComplexity: null, header: null });
        });
    }

    PlayClose() {
        if (this.props.onHide)
            this.props.onHide();
        this.ResetPage();
    }

    ResetPage() {
        this.setState({
            participantResults: [],
            selectedInstance: -1,
            resultDataQuery: {
                pageNumber: 1,
                itemsInPage: 1000,
                sortingCol: "ModifiedDate",
                sortType: "DESC",
                filter: "",
            },
            resultsSummary: null,
            header: null,
            totalCount: 0,
        });
    }

    PlaySelectInstance(instance, index) {
        this.setState({ selectedInstance: instance, selectedIndex: index }, () => {
            //console.debug("PLAY", this.state.cognitiveItem, `INSTANCE = ${this.state.selectedInstance}`);
        });
    }

    UpdateHeader(header) {
        this.setState({ header: header })
    }

    QuitCognitiveItemOpen() {
        let updated = update(this.state.showModal, { confirmQuit: { $set: true } });
        this.setState({ showModal: updated })
    }

    QuitCognitiveItemClose() {
        let updated = update(this.state.showModal, { confirmQuit: { $set: false } });
        this.setState({ showModal: updated })
    }

    QuitCognitiveItem() {
        //console.debug("QuitCognitiveItem", this.state.participantResults)
        let updated = update(this.state.showModal, { confirmQuit: { $set: false } });
        if (!this.state.participantResults || (this.state.participantResults && this.state.participantResults.length === 0)) {
            this.setState({ showModal: updated }, () => {
                this.PlayClose();
            })
        } else {
            this.setState({ showModal: updated }, () => {
                this.RefreshResults();
            })
        }
    }

    SetSelectedComplexity(complexity) {
        this.setState({ selectedComplexity: complexity }, () => {
            //console.debug("SET COMPLEXITY", this.state.selectedComplexity);
        })
    }

    GetMostRecentEndComplexity() {
        if (this.state.selectedComplexity && this.state.selectedComplexity > 0) {
            //console.debug("DEFAULT COMPLEXITY (manual)", this.state.selectedComplexity);
            return this.state.selectedComplexity;
        } else {
            if (this.state.cognitiveItem.type === "BRAINTRAINING" && this.state.resultsSummary && this.state.resultsSummary.mostRecentComplexity) {
                //console.debug("DEFAULT COMPLEXITY (automatic)", this.state.resultsSummary.mostRecentComplexity);
                return this.state.resultsSummary.mostRecentComplexity;
            } else {
                return 1;
            }
        }
    }

    GetSelectedName() {
        if (this.state.cognitiveItem && this.state.cognitiveItem.text && this.state.cognitiveItem.text.text) {
            return this.state.cognitiveItem.text.text;
        } else if (this.state.cognitiveItem && this.state.cognitiveItem.code && this.state.cognitiveItem.version) {
            return `${this.state.cognitiveItem.code} v${this.state.cognitiveItem.version}`;
        } else {
            return "Brain Training Game";
        }
    }

    GetMaxComplexity() {
        if (this.state.resultsSummary)
            return this.state.resultsSummary.maxComplexity
        else
            return 0;
    }

    GetScore() {
        let score = this.state.header && this.state.header.score ? this.state.header.score : '';
        if (score !== "Score: 000")
            return score;
    }

    GetProgress() {
        return this.state.header && this.state.header.progress ? this.state.header.progress : '';
    }

    GetTime() {
        return this.state.header && this.state.header.time ? this.state.header.time : '';
    }

    HasValidResults() {
        return this.state.resultsSummary && this.state.resultsSummary.passedCount > 0;
    }

    HasAnyResults() {
        return this.state.resultsSummary !== null;
    }

    Mute() {
        if (this.playForm.current)
            this.playForm.current.Mute();
    }

    Unmute() {
        if (this.playForm.current)
            this.playForm.current.Unmute();
    }

    NeedsPlayFooter() {
        let result = false;
        if (this.state.selectedInstance === -1) {
            result = !this.props.device.ShowMobileUI() || !this.HasInlineContinueButton();
        } else if (!this.props.device.ShowMobileUI()) {
            result = !this.props.device.ShowMobileUI();
        }
        return result;
    }

    HasInlineContinueButton() {
        return this.props.device.ShowMobileUI() && this.state.cognitiveItem && this.state.cognitiveItem.category === "PROTECT";
    }

    IsPlacebo() {
        if (this.props.isPlacebo)
            return this.props.isPlacebo;
        else
            return false;
    }

    GetMostRecentErrorMessage() {
        let error = null;
        if (this.state.participantResults) {
            let notPassedResults = this.state.participantResults.filter(r => r.status !== "PASSED");
            if (notPassedResults.length > 0 && notPassedResults[0].exception)
                error = notPassedResults[0].exception;
        }
        return error;
    }

    GetMostRecentTotalCorrect() {
        let totalCorrect = 0;
        if (this.state.participantResults) {
            let passedResults = this.state.participantResults.filter(r => r.status === "PASSED");
            if (passedResults.length > 0 && passedResults[0].rawResults) {
                let rawResults = JSON.parse(passedResults[0].rawResults);
                if (rawResults.scores) {
                    console.log("SCORES", rawResults.scores)
                    if (rawResults.scores.totalCorrect > 0)
                        totalCorrect = rawResults.scores.totalCorrect;
                    else if (rawResults.scores.ncorrect > 0)
                        totalCorrect = rawResults.scores.ncorrect;
                    else if (rawResults.scores.numCorrect > 0)
                        totalCorrect = rawResults.scores.numCorrect;
                    else if (rawResults.scores.numCompleted > 0)
                        totalCorrect = rawResults.scores.numCompleted;
                }
            }
        }
        return totalCorrect;
    }

    GetMostRecentTotalErrors() {
        let totalErrors = 0;
        if (this.state.participantResults) {
            let passedResults = this.state.participantResults.filter(r => r.status === "PASSED");
            if (passedResults.length > 0 && passedResults[0].rawResults) {
                let rawResults = JSON.parse(passedResults[0].rawResults);
                if (rawResults.scores) {
                    if (rawResults.scores.totalErrors > 0)
                        totalErrors = rawResults.scores.totalErrors;
                    else if (rawResults.scores.nincorrect > 0)
                        totalErrors = rawResults.scores.nincorrect;
                    else if (rawResults.scores.numIncorrect > 0)
                        totalErrors = rawResults.scores.numIncorrect;
                    else if (rawResults.scores.numFailed > 0)
                        totalErrors = rawResults.scores.numFailed;
                }
            }
        }
        return totalErrors;
    }

    LoadPage() {
        if (this.props.device.ShowMobileUI()) {

            this.GetCognitiveItem(this.props.code, this.props.version, this.props.category, (cognitiveItem) => {

                let updatedDataQuery = update(this.state.dataQuery, { itemsInPage: { $set: 0 } });
                this.setState({ dataQuery: updatedDataQuery }, () => {
                    //console.debug("LOAD", cognitiveItem);
                    this.GetParticipantResults(this.state.cognitiveItem);
                });

            })

        } else {

            this.GetCognitiveItem(this.props.code, this.props.version, this.props.category, (cognitiveItem) => {
                //console.debug("LOAD", this.state.cognitiveItem);
                this.GetParticipantResults(this.state.cognitiveItem);
            })

        }
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps) {

    }

    render() {
        return (
            <div>

                <Modal
                    show={this.props.show}
                    contentClassName={`cognitive-item-modal-${this.props.cognitiveItemType === "BRAINTRAINING" ? 'game' : 'test'}`}
                    onHide={() => this.PlayClose()}
                    onEntered={() => this.LoadPage()}
                    fullscreen={true}
                    backdrop="static"
                    keyboard={false}
                    scrollable>

                    {((this.state.selectedInstance && this.state.selectedInstance !== 0) || (this.state.cognitiveItem && this.state.cognitiveItem.category !== "DIPLORIA")) && (
                        <Modal.Header className="modal-header-app" data-bs-theme="dark">
                            <Modal.Title className="w-100">
                                {this.RenderPlayHeader()}
                            </Modal.Title>
                        </Modal.Header>
                    )}

                    <Modal.Body className={`p-0 cognitive-item-modal-body-${this.props.cognitiveItemType === "BRAINTRAINING" ? 'game' : 'test'}`} ref={this.cognitiveItemModalBody}>
                        {this.RenderResultsOrCognitiveItem()}
                        {this.RenderPlaySubHeader()}
                    </Modal.Body>

                    {this.NeedsPlayFooter() && (
                        <Modal.Footer className={`cognitive-item-modal-footer-${this.props.cognitiveItemType === "BRAINTRAINING" ? 'game' : 'test'}`}>
                            {this.RenderPlayOptions()}
                        </Modal.Footer>
                    )}

                </Modal>

                <ModalConfirm
                    ref={this.confirmQuitModal}
                    show={this.state.showModal.confirmQuit}
                    onCancel={() => this.QuitCognitiveItemClose()}
                    onConfirm={() => this.QuitCognitiveItem()}
                    title="Are you sure?"
                    message="If you leave now we will not be able to save any results and you will need to start again."
                    device={this.props.device}
                    user={this.props.user}
                    theme={this.props.theme}
                />

            </div>
        );
    }

    RenderResultsOrCognitiveItem() {
        if (this.state.cognitiveItem) {
            if (this.state.selectedInstance === -1 && this.state.resultsSummary) {

                if (this.state.resultsSummary.passedCount > 0) {

                    return (
                        <div className="px-4 pt-2 pb-2 cognitive-item-summary">
                            <BrainTrainingSummary
                                cognitiveItem={this.state.cognitiveItem}
                                resultsSummary={this.state.resultsSummary}
                                totalCount={this.state.resultDataQuery.totalCount}
                                mostRecentTotalCorrect={this.GetMostRecentTotalCorrect()}
                                mostRecentTotalErrors={this.GetMostRecentTotalErrors()}
                                user={this.props.user}
                                device={this.props.device}
                                theme={this.props.theme}
                                isPlacebo={this.IsPlacebo()}
                                onStart={() => this.PlaySelectInstance(0, -1)}
                                hasInlineContinueButton={this.HasInlineContinueButton()}
                                showTargetProgress={this.props.showTargetProgress}
                            />
                        </div>
                    );

                } else {

                    return (
                        <div className="px-4 pt-2 pb-2 cognitive-item-summary">
                            <h1>Sorry!</h1>
                            <p>We haven't got any valid results to display yet.</p>
                            <p>This is something we will be looking into as soon as possible.</p>
                            {this.HasInlineContinueButton() && (
                                <div className="d-flex justify-content-center p-3 pb-4">
                                    <ButtonContinueGame text="Play Game" variant={this.props.theme.GetPlayBackgroundVariant()} onClick={() => { this.PlaySelectInstance(0, -1) }} />
                                </div>
                            )}
                        </div>
                    );

                }

            } else {

                //console.debug("LOAD GAME IMMEDIATELY", this.state.selectedInstance === -1, this.HasValidResults())
                return (
                    <PlayForm
                        ref={this.playForm}
                        cognitiveItemType="BRAINTRAINING"
                        
                        mode={this.props.mode}
                        inputType={this.props.inputType}

                        item={this.state.cognitiveItem}
                        instance={this.state.selectedInstance}

                        languageCode={this.state.selectedLanguage}
                        maxComplexity={this.GetMostRecentEndComplexity()}
                        defaultStartTime={new Date()}
                        onSavedCognitiveItemResults={(cognitiveItemResults, closePlayForm) => this.SavedCognitiveItemResults(cognitiveItemResults, closePlayForm)}
                        onUpdateHeader={(header) => this.UpdateHeader(header)}
                        user={this.props.user}
                        device={this.props.device}
                        theme={this.props.theme}
                        className="full-size"
                        isPlacebo={this.IsPlacebo()}
                    />
                );

            }
        }
    }

    RenderPlayHeader() {
        if (this.state.cognitiveItem) {
            if (this.state.header) {

                return (
                    <div className="d-flex justify-content-between">
                        <div>{this.GetSelectedName()}</div>
                        {this.state.header.time && (
                            <div>{this.GetTime()}</div>
                        )}
                        {this.props.device.ShowMobileUI() && (
                            <div>
                                <Button className="text-nowrap" variant="danger" onClick={() => this.QuitCognitiveItemOpen()}>
                                    Quit
                                </Button>
                            </div>
                        )}
                    </div>
                );

            } else {
                if (this.state.cognitiveItem.type === "BRAINTRAINING" && this.state.selectedInstance === -1) {

                    return (
                        <div className="d-flex justify-content-between">
                            <div className="d-flex justify-content-start">
                                {this.props.device.ShowMobileUI() && (
                                    <div className="pe-3" onClick={() => this.PlayClose()}>
                                        <ButtonBackApp onClick={() => this.PlayClose()}></ButtonBackApp>
                                    </div>
                                )}
                                <div className="text-start">{this.GetSelectedName()}</div>
                            </div>
                            {!this.props.isPlacebo && (
                                <ComplexitySelect
                                    levels={this.state.brainTrainingLevels}
                                    complexity={this.GetMostRecentEndComplexity()}
                                    maxComplexity={this.props.user.IsGuest() ? this.GetMaxComplexity() : 0}
                                    onUpdated={(complexity) => { this.SetSelectedComplexity(complexity) }}
                                />
                            )}
                        </div>
                    );

                } else {

                    return (
                        <div className="d-flex justify-content-start">
                            {this.props.device.ShowMobileUI() && (
                                <div className="pe-3" onClick={() => { this.state.selectedInstance === -1 ? this.PlayClose() : this.QuitCognitiveItemOpen() }}>
                                    <ButtonBackApp onClick={() => { this.state.selectedInstance === -1 ? this.PlayClose() : this.QuitCognitiveItemOpen() }}></ButtonBackApp>
                                </div>
                            )}
                            <div className="text-start w-100">{this.GetSelectedName()}</div>
                        </div>
                    );

                }
            }
        }
    }

    RenderPlaySubHeader() {
        if (this.state.header) {

            return (
                <div className={`d-flex justify-content-between ps-2 pt-2 cognitive-item-sub-header ${this.props.theme.GetTextBootstrapContrastVariant()}`}>
                    <div><ButtonMuteToggle onMute={() => { this.Mute() }} onUnmute={() => { this.Unmute() }} /></div>
                    <div className="d-flex justify-content-between ps-3 pe-3">
                        {this.state.header.progress && (
                            <div>{this.GetProgress()}</div>
                        )}
                        {this.state.header.score && (
                            <div>{this.GetScore()}</div>
                        )}
                    </div>
                </div>
            );

        } else {

            return (<div></div>);

        }
    }

    RenderPlayOptions() {
        let buttons = [];
        let index = 0;

        if (this.state.selectedInstance === -1 && !this.HasInlineContinueButton()) {

            if (!this.props.device.ShowMobileUI()) {
                buttons.push(
                    <Button key={`BTN-${index++}`} variant="secondary" onClick={() => this.state.selectedInstance === -1 ? this.PlayClose() : this.QuitCognitiveItemOpen()}>
                        Close
                    </Button>
                );
            }

            buttons.push(
                <Button key={`BTN-${index++}`} variant="primary" onClick={() => this.PlaySelectInstance(0, -1)}>
                    Start a New Game
                </Button>
            );

        } else {

            if (!this.props.device.ShowMobileUI()) {
                buttons.push(
                    <Button key={`BTN-${index++}`} variant="danger" onClick={() => this.state.selectedInstance === -1 ? this.PlayClose() : this.QuitCognitiveItemOpen()}>
                        Quit
                    </Button>
                );
            }

        }
        return buttons;
    }
}
