import React, { Component, Suspense } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { Layout, LayoutAuth, LayoutAdmin, LayoutLogin, LayoutPages } from './components/Layout';
import './custom.css';
import { Device, Theme, User } from './components/shared/GlobalFns'
import { AccountApi, StudyApi, NotificationApi } from './components/shared/BEACONApi'
import { Home } from "./components/Home";
import { Dashboard } from "./components/Dashboard";
import { Games } from "./components/Games";
import { MyDetails } from "./components/MyDetails";
import { Alerts } from "./components/Alerts";
import { Reports } from "./components/Reports";
import { Questionnaires } from "./components/Questionnaires";
import { GPSurgeries } from "./components/GPSurgeries";
import { CognitiveItems } from "./components/CognitiveItems";
import { BatteryItems } from "./components/BatteryItems";
import { StudyAdmin } from './components/StudyAdmin';
import { DashboardAdmin } from './components/DashboardAdmin';
import { Randomise } from './components/Randomise';
import { Privacy } from './components/Privacy';
import { Terms } from './components/Terms';
import { FAQ } from './components/FAQ';
import { ContactDetails } from './components/ContactDetails';
import Spinner from 'react-bootstrap/Spinner';

export default class App extends Component {
    static displayName = App.name;

    constructor(props) {
        super(props);
        this.state = {
            user: new User(),
            device: new Device(),
            theme: new Theme(),
            schedule: null,
            keepLoggedInDelay: 5 * 60 * 1000, // 5 minute poll server interval
            installHandler: null,
            notifications: [],
        };

        this.timer = null;

        this.AccountApi = new AccountApi();
        this.StudyApi = new StudyApi();
        this.NotificationApi = new NotificationApi();

        this.ContextMenu = this.ContextMenu.bind(this);
    }

    async GetScheduleData(callBackSuccessFn, callBackErrorFn) {

        await this.StudyApi.Schedule((data) => {

            this.setState({ schedule: data });
            if (callBackSuccessFn)
                callBackSuccessFn(this.state.schedule);

        }, (errors) => {

            if (callBackErrorFn)
                callBackErrorFn(errors);

            // TODO: Set user as logged out and clear user study schedule (without getting into a loop)

        });

    }

    async GetNotifications() {

        await this.NotificationApi.Notifications((data) => {

            this.setState({
                notifications: data,
            });

        }, (error) => {

            this.setState({
                notifications: [],
            });

        })

    }

    async GetMyData() {

        await this.AccountApi.MyDetails((data) => {

            this.setState({
                user: new User(data),
            }, async () => {

                //console.debug("User", this.state.user.Get())
                if (this.IsLoggedIn()) {

                    await this.GetScheduleData(async () => {
                        await this.GetNotifications()
                    });

                    this.timer = setTimeout(() => {
                        this.KeepLoggedIn();
                    }, this.state.keepLoggedInDelay)

                }

            });

        }, (error) => {

            console.warn("GetMyData", error)
            this.setState({
                user: new User(),
            });

        });

    }

    async KeepLoggedIn() {

        await this.AccountApi.KeepLoggedIn(() => {

            if (this.IsLoggedIn()) {

                this.setState({
                    timer: setTimeout(() => {
                        this.KeepLoggedIn();
                    }, this.state.keepLoggedInDelay)
                });

            } else {

                console.debug("Automatically logged out");
                this.GetMyData();

            }

        }, (errors) => {

            console.warn("Automatically logged out", errors);
            this.GetMyData();

        });

    }

    GetOrientation() {
        var orientation = window.innerWidth < window.innerHeight ? "Portrait" : "Landscape";
        if (window.orientation) {
            switch (window.orientation) {
                case 0:
                case 180:
                    orientation = "Portrait";
                    break;
                case -90:
                case 90:
                    orientation = "Landscape";
                    break;
                default:
                    break;
            }
        }
        return orientation;
    }

    IsLoggedIn() {
        if (this.state.user)
            return this.state.user.IsLoggedIn();
        else
            return false;
    }

    ContextMenu(event) {
        if (this.state.device.ShowMobileUI())
            event.preventDefault()
    }

    Resize() {

        let orientation = this.GetOrientation();
        let isTouchDevice = matchMedia('(hover: none), (pointer: coarse)').matches;
        let isPhone = isTouchDevice && ((orientation === "Portrait" && window.innerWidth <= 500) || (orientation === "Landscape" && window.innerHeight <= 500));
        let isTablet = isTouchDevice && !isPhone;
        let isInstalledPWA = window.matchMedia('(display-mode: window-controls-overlay)').matches || window.matchMedia('(display-mode: standalone)').matches;

        if (!this.state.device || this.state.device.IsPhone() !== isPhone || this.state.device.Orientation() !== orientation || this.state.device.IsInstalledPWA() !== isInstalledPWA) {

            let device = new Device(isTouchDevice, isPhone, isTablet, orientation, isInstalledPWA, Intl.DateTimeFormat().resolvedOptions());
            let currentTheme = this.state.theme ? this.state.theme : new Theme(device);

            this.setState({ device: device, theme: currentTheme }, () => {

                document.removeEventListener('contextmenu', this.ContextMenu);
                document.addEventListener('contextmenu', this.ContextMenu);

                //console.debug("Device", this.state.device.Get())
                //console.debug("Theme", this.state.theme.Get())
            })

        }

    }

    GetInstallPrompt() {
        if (!this.state.installHandler)
            window.addEventListener('beforeinstallprompt', (installHandler) => {

                //console.debug("Set Install Handler")
                this.setState({ installHandler: installHandler })

            });
    }

    componentDidMount() {

        // Stop diploria tests writing to console
        window.document.enableDiploriaLogging = false;

        this.GetInstallPrompt();
        this.GetMyData();
        window.addEventListener("resize", this.Resize.bind(this));
        this.Resize();

    }

    componentWillUnmount() {

        //console.debug("Clear keep alive");
        clearInterval(this.timer);
        window.removeEventListener("resize", this.Resize.bind(this));
        document.removeEventListener('contextmenu', this.ContextMenu);

    }

    authorizationRoutes() {
        return ApiAuthorizationRoutes.map((route, index) => {
            const { path, element, ...rest } = route;
            return (
                <Route
                    key={index}
                    path={path}
                    element={
                        <LayoutAuth theme={this.state.theme} device={this.state.device} user={this.state.user}>
                            {element}
                        </LayoutAuth>
                    }
                    {...rest}
                />
            );
        });
    }

    render() {
        return (
            <Suspense fallback={this.PageNotReady("Suspense")}>
                <Routes>

                    <Route path="/" element={this.HomePage()} />
                    <Route path="/games" element={this.GamesPage()} />
                    <Route path="/dashboard" element={this.DashboardPage()} />
                    <Route path="/mydetails" element={this.MyDetailsPage()} />
                    <Route path="/alerts" element={this.MyNotificationsPage()} />

                    <Route path="/privacy" element={this.PrivacyPage()} />
                    <Route path="/terms" element={this.TermsPage()} />
                    <Route path="/faq" element={this.FAQPage()} />
                    <Route path="/contactDetails" element={this.ContactDetailsPage()} />

                    <Route path="/dashboardadmin" element={this.AdminPage(
                        <DashboardAdmin theme={this.state.theme} device={this.state.device} user={this.state.user} />
                    )} />

                    <Route path="/reports" element={this.AdminPage(
                        <Reports theme={this.state.theme} device={this.state.device} user={this.state.user} schedule={this.state.schedule} />
                    )} />

                    <Route path="/studyadmin" element={this.AdminPage(
                        <StudyAdmin theme={this.state.theme} device={this.state.device} user={this.state.user} schedule={this.state.schedule}
                            getSchedule={(callBackSuccessFn, callBackErrorFn) => { this.GetScheduleData(callBackSuccessFn, callBackErrorFn) }}
                        />
                    )} />

                    <Route path="/questionnaires" element={this.AdminPage(
                        <Questionnaires theme={this.state.theme} device={this.state.device} user={this.state.user} />
                    )} />

                    <Route path="/GPSurgeries" element={this.AdminPage(
                        <GPSurgeries theme={this.state.theme} device={this.state.device} user={this.state.user} />
                    )} />

                    <Route path="/Batteries" element={this.AdminPage(
                        <BatteryItems theme={this.state.theme} device={this.state.device} user={this.state.user} cognitiveItemType="COGNITIVETEST" />
                    )} />

                    <Route path="/CognitiveTests" element={this.AdminPage(
                        <CognitiveItems theme={this.state.theme} device={this.state.device} user={this.state.user} cognitiveItemType="COGNITIVETEST" />
                    )} />

                    <Route path="/Packages" element={this.AdminPage(
                        <BatteryItems theme={this.state.theme} device={this.state.device} user={this.state.user} cognitiveItemType="BRAINTRAINING" />
                    )} />

                    <Route path="/BrainTrainingGames" element={this.AdminPage(
                        <CognitiveItems theme={this.state.theme} device={this.state.device} user={this.state.user} cognitiveItemType="BRAINTRAINING" />
                    )} />

                    <Route path="/Randomisation" element={this.AdminPage(
                        <Randomise theme={this.state.theme} device={this.state.device} user={this.state.user} />
                    )} />

                    {this.authorizationRoutes()}

                    <Route path="*" element={<Navigate to={`/?route=unknown`} />} />

                </Routes>
            </Suspense>
        );
    }

    PageNotReady(reason) {
        return (

            <Layout theme={this.state.theme} device={this.state.device} user={this.state.user} notifications={this.state.notifications}>
                <div className="busy-overlay col d-flex align-items-center justify-content-center">
                    <Spinner animation="border" role="status" variant="light" />
                </div>
            </Layout>

        );
    }

    DashboardPage() {
        return (

            <Layout theme={this.state.theme} device={this.state.device} user={this.state.user} notifications={this.state.notifications}>
                <AuthorizeRoute element={
                    <Dashboard
                        theme={this.state.theme}
                        device={this.state.device}
                        user={this.state.user}
                        schedule={this.state.schedule}
                        dashboardType="ONCE"
                        getSchedule={(callBackSuccessFn, callBackErrorFn) => { this.GetScheduleData(callBackSuccessFn, callBackErrorFn) }}
                    />
                } />
            </Layout>

        );
    }

    GamesPage() {
        return (

            <Layout theme={this.state.theme} device={this.state.device} user={this.state.user} notifications={this.state.notifications}>
                <AuthorizeRoute element={
                    <Games
                        theme={this.state.theme}
                        device={this.state.device}
                        user={this.state.user}
                        schedule={this.state.schedule}
                        getSchedule={(callBackSuccessFn, callBackErrorFn) => { this.GetScheduleData(callBackSuccessFn, callBackErrorFn) }}
                    />
                } />
            </Layout>

        );
    }

    MyDetailsPage() {
        return (

            <Layout theme={this.state.theme} device={this.state.device} user={this.state.user} notifications={this.state.notifications}>
                <AuthorizeRoute element={
                    <MyDetails
                        theme={this.state.theme}
                        device={this.state.device}
                        user={this.state.user}
                        schedule={this.state.schedule}
                        dashboardType={"GLOBAL"}
                        getSchedule={(callBackSuccessFn, callBackErrorFn) => { this.GetScheduleData(callBackSuccessFn, callBackErrorFn) }}
                    />
                } />
            </Layout>

        );
    }

    MyNotificationsPage() {
        return (

            <Layout theme={this.state.theme} device={this.state.device} user={this.state.user} notifications={this.state.notifications}>
                <AuthorizeRoute element={
                    <Alerts
                        theme={this.state.theme}
                        device={this.state.device}
                        user={this.state.user}
                        notifications={this.state.notifications}
                    />
                } />
            </Layout>

        );
    }

    PrivacyPage() {
        return (

            <LayoutPages theme={this.state.theme} device={this.state.device} user={this.state.user}>
                <Privacy
                    theme={this.state.theme}
                    device={this.state.device}
                    user={this.state.user}
                />
            </LayoutPages>

        );
    }

    TermsPage() {
        return (

            <LayoutPages theme={this.state.theme} device={this.state.device} user={this.state.user}>
                <Terms
                    theme={this.state.theme}
                    device={this.state.device}
                    user={this.state.user}
                />
            </LayoutPages>

        );
    }

    FAQPage() {
        return (

            <LayoutPages theme={this.state.theme} device={this.state.device} user={this.state.user}>
                <FAQ
                    theme={this.state.theme}
                    device={this.state.device}
                    user={this.state.user}
                />
            </LayoutPages>

        );
    }

    ContactDetailsPage() {
        return (

            <LayoutPages theme={this.state.theme} device={this.state.device} user={this.state.user}>
                <ContactDetails
                    theme={this.state.theme}
                    device={this.state.device}
                    user={this.state.user}
                />
            </LayoutPages>

        );
    }

    HomePage() {
        if (this.state.user.IsLoggedIn()) {
            return this.DashboardPage();
        } else {
            return (

                <LayoutLogin installHandler={this.state.installHandler} theme={this.state.theme} device={this.state.device} user={this.state.user}>
                    <Home theme={this.state.theme} user={this.state.user} device={this.state.device} />
                </LayoutLogin>

            );
        }
    }

    AdminPage(element) {
        if (this.state.user.IsUser() || this.state.user.IsAdmin() || this.state.user.IsDeveloper()) {
            return (

                <LayoutAdmin theme={this.state.theme} device={this.state.device} user={this.state.user} notifications={this.state.notifications}>
                    <AuthorizeRoute element={element} />
                </LayoutAdmin>

            );
        } else {
            return this.MyDetailsPage();
        }
    }
}
