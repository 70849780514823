import React, { Component } from 'react';
import { ModalConfirm } from './shared/ModalConfirm';
import { ButtonAdd } from './shared/Buttons/Add';
import { ButtonEdit } from './shared/Buttons/Edit';
import { ButtonPlay } from './shared/Buttons/Play';
import { ButtonStatus } from './shared/Buttons/Status';
import { StatusSelect } from './shared/Inputs/StatusSelect';
import { ButtonDelete } from './shared/Buttons/Delete';
import { EditForm } from './CognitiveItem/EditForm';
import { TablePagination } from './shared/TablePagination';
import { BrainTrainingModal } from './shared/CognitiveItem/BrainTrainingModal'
import { CognitiveItemModal } from './shared/CognitiveItem/CognitiveItemModal'
import update from 'immutability-helper';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { CognitiveItemsApi } from './shared/CTAGApi';
import { Validate } from './shared/GlobalFns';

import './CognitiveItems.css'

export class CognitiveItems extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            list: [],
            fields: [
                { key: 'status', label: 'Status', className: 'hide-if-smaller-than-md' },
                { key: 'code', label: 'Name' },
                { key: 'version', label: 'Version', className: 'hide-if-smaller-than-sm' },
                { key: 'category', label: 'Category', className: 'hide-if-smaller-than-lg' },
                { key: 'dataPoints', label: 'Data Points', className: 'hide-if-smaller-than-xxl' },
                { key: 'numberOfResults', label: 'Total Results', className: 'hide-if-smaller-than-xl' },
                { key: 'actions', label: '' },
            ],
            resultsFields: [
                { key: 'status', label: 'Status' },
                { key: 'startTime', label: 'Start Date' },
                { key: 'endTime', label: 'End Date' },
                { key: 'instance', label: 'Instance' },
                { key: 'actions', label: '' },
            ],
            showModal: {
                confirm: false,
                confirmDevDelete: false,
                confirmQuit: false,
                play: false,
                edit: false,
            },
            selectedItem: {},
            selectedIndex: -1,
            selectedInstance: -1, // -1 = List results (if available), 0 = start new session, >0 show results for that session
            dataQuery: {
                pageNumber: 1,
                itemsInPage: 10,
                sortingCol: "Code",
                sortType: "ASC",
                filter: "",
            },
            resultDataQuery: {
                pageNumber: 1,
                itemsInPage: 5,
                sortingCol: "ModifiedDate",
                sortType: "DESC",
                filter: "",
            },
            resultsSummary: {
                mostRecentComplexity: 0,
                previousComplexity: 0,
                maxComplexity: 0,
                timePlayedToday: 0,
                timePlayedLast7Days: 0,
                timePlayedLast30Days: 0,
                timePlayedTotal: 0,
                passedCount: 0,
            },
            selectedLanguage: 'en-GB',
            participantsResults: [],
            header: null,
            brainTrainingLevels: [
                { text: "EASY", award: "", awardText: "", value: 1, className: "text-nowrap btn btn-sm btn-success rounded-pill white" },
                { text: "MEDIUM", award: "BRONZE", awardText: "Great<br/>Going", value: 300, className: "text-nowrap btn btn-sm btn-info rounded-pill white" },
                { text: "HARD", award: "SILVER", awardText: "Top<br/>Tier", value: 500, className: "text-nowrap btn btn-sm btn-warning rounded-pill white" },
                { text: "DIFFICULT", award: "GOLD", awardText: "Genius<br/>Level", value: 750, className: "text-nowrap btn btn-sm btn-warning rounded-pill white" },
                { text: "IMPOSSIBLE", award: "PLATINUM", awardText: "WOW!!!", value: 900, className: "text-nowrap btn btn-sm btn-danger rounded-pill white" }
            ]
        };

        this.editForm = React.createRef();
        this.playForm = React.createRef();
        this.confirmModal = React.createRef();
        this.confirmDevDeleteModal = React.createRef();
        this.confirmQuitModal = React.createRef();

        this.cognitiveItemsApi = new CognitiveItemsApi();
        this.validate = new Validate();
    }

    async GetData() {

        await this.cognitiveItemsApi.Query(this.state.dataQuery, this.props.cognitiveItemType, (data) => {

            let updatedDataQuery = update(data.query, { filter: { $set: this.state.dataQuery.filter }, totalCount: { $set: data.totalCount } });
            this.setState({
                data: data.data,
                dataQuery: updatedDataQuery,
            }, () => {
                this.GetCognitiveItemsList();
            });

        }, (error) => {

        })

    }

    async GetCognitiveItemsList() {

        await this.cognitiveItemsApi.List(this.props.cognitiveItemType, (data) => {

            this.setState({
                list: data,
            });

        }, (error) => {

        })

    }

    async SaveItem(index, item) {

        await this.cognitiveItemsApi.SaveItem(index, item, (data) => {

            this.UpdateItemInList(data);
            this.EditClose();

        }, (errors) => {

            if (this.editForm.current) this.editForm.current.SaveFailed(errors)

        });

    }

    async DeleteItem() {

        await this.cognitiveItemsApi.DeleteItem(this.state.selectedIndex, this.state.selectedItem, (deletedIndex) => {

            if (deletedIndex >= 0) {
                let updatedData = update(this.state.data, { $splice: [[deletedIndex, 1]] });
                this.setState({ showModal: { confirm: false }, data: updatedData });
                this.GetData();
            }

        }, (errors) => {

            if (this.confirmModal.current) this.confirmModal.current.ConfirmFailed(errors)

        });

    }

    async DevDeleteItem() {

        await this.cognitiveItemsApi.DeleteItem(this.state.selectedIndex, this.state.selectedItem, (deletedIndex) => {

            if (deletedIndex >= 0) {
                let updatedData = update(this.state.data, { $splice: [[deletedIndex, 1]] });
                this.setState({ showModal: { confirmDevDelete: false }, data: updatedData });
                this.GetData();
            }

        }, (errors) => {

            if (this.confirmDevDeleteModal.current) this.confirmDevDeleteModal.current.ConfirmFailed(errors)

        });

    }

    TabulateData() {
        let rows = [];
        Array.prototype.forEach.call(this.state.data, (data) => {
            let row = {};
            this.state.fields.forEach((field) => {
                switch (field.key) {
                    case 'code':
                        if (this.props.device && this.props.device.ShowMobileUI()) {
                            row[field.key] = data.text.text ? `<div>${data.text.text}</div>` : `<div>${data[field.key]}</div>`;
                        } else {
                            row[field.key] = data.text.text ? `<div>${data[field.key]}</div><div><i><small>${data.text.text}</small></i></div>` : `<div>${data[field.key]}</div>`;
                        }
                        break;
                    case 'category':
                    case 'inputType':
                        row[field.key] = data[field.key];
                        break;
                    case 'dataPoints':
                        row[field.key] = data[field.key].map(dp => dp.code).join(", ");
                        break;
                    case 'version':
                    case 'numberOfResults':
                        row[field.key] = data[field.key] ?? 0;
                        break;
                    case 'status':
                        row[field.key] = 'Status';
                        break;
                    case 'actions':
                        row[field.key] = 'Actions';
                        break;
                    default:
                        if (data[field.key])
                            row[field.key] = JSON.stringify(data[field.key]);
                        break;
                }
            });
            rows.push(row);
        });
        return rows;
    }

    SetSelectedComplexity(complexity) {
        this.setState({ selectedComplexity: complexity }, () => {
            //console.debug("SET COMPLEXITY", this.state.selectedComplexity);
        })
    }

    GetMostRecentEndComplexity() {
        if (this.state.selectedComplexity && this.state.selectedComplexity > 0) {
            //console.debug("DEFAULT COMPLEXITY (manual)", this.state.selectedComplexity);
            return this.state.selectedComplexity;
        } else {
            if (this.props.cognitiveItemType === "BRAINTRAINING" && this.state.resultsSummary && this.state.resultsSummary.mostRecentComplexity) {
                //console.debug("DEFAULT COMPLEXITY (automatic)", this.state.resultsSummary.mostRecentComplexity);
                return this.state.resultsSummary.mostRecentComplexity;
            } else {
                return 1;
            }
        }
    }

    GetSelectedName() {
        if (this.state.selectedItem.text && this.state.selectedItem.text.text) {
            return this.state.selectedItem.text.text;
        } else if (this.state.selectedItem && this.state.selectedItem.code && this.state.selectedItem.version) {
            return `${this.state.selectedItem.code} v${this.state.selectedItem.version}`;
        }  else {
            if (this.props.cognitiveItemType === "COGNITIVETEST")
                return "Cognitive Test";
            else
                return "Brain Training Game";
        }
    }

    EditOpen(index, item) {
        if (item)
            this.setState({ showModal: { edit: true }, selectedIndex: index ?? -1, selectedItem: item });
        else
            this.setState({ showModal: { edit: true }, selectedIndex: -2, selectedItem: { type: this.props.cognitiveItemType, code: '' } });
    }
    EditClose() {
        this.setState({ showModal: { edit: false } });
    }
    EditSave() {
        if (this.editForm.current) this.editForm.current.SaveClick();
    }

    PlayOpen(index, item) {
        if (item) {
            this.props.theme.SetBackgroundColour(item.backgroundColour);
            this.setState({ showModal: { play: true }, selectedIndex: index ?? -1, selectedItem: item });
        }
    }

    PlayClose() {
        this.setState({ showModal: { play: false } }, () => {
            this.props.theme.SetBackgroundColour();
        });
    }

    SavedCognitiveItemResults(cognitiveItemResults) {
        let index = this.state.data.findIndex(item => item.id === this.state.selectedItem.id);
        if (index !== -1) {
            this.state.data[index].numberOfResults++;
        }
    }

    DevDeleteOpen(index, item) {
        this.setState({ showModal: { confirmDevDelete: true }, selectedIndex: index ?? -1, selectedItem: item });
    }
    DevDeleteClose() {
        this.setState({ showModal: { confirmDevDelete: false } });
    }

    DeleteOpen(index, item) {
        this.setState({ showModal: { confirm: true }, selectedIndex: index ?? -1, selectedItem: item });
    }
    DeleteClose() {
        this.setState({ showModal: { confirm: false } });
    }

    UpdateItemInList(data, closeEditForm) {
        let index = this.state.data.findIndex(item => item.id === data.id);
        let updatedData = {};
        if (index >= 0)
            updatedData = update(this.state.data, { $splice: [[index, 1, data]] });
        else
            updatedData = update(this.state.data, { $splice: [[0, 0, data]] });
        this.setState({ data: updatedData });
    }

    SetItemStatus(status) {
        if (this.editForm.current)
            this.editForm.current.SetStatus(status);
    }

    componentDidMount() {
        if (this.props.device.ShowMobileUI()) {
            let updatedDataQuery = update(this.state.dataQuery, { itemsInPage: { $set: 0 } });
            this.setState({ dataQuery: updatedDataQuery }, () => {
                this.GetData();
            });
        } else {
            this.GetData();
        }
    }

    render() {
        return (
            <div className="flex-fill">
                
                {this.RenderCognitiveItems()}

                <Modal show={this.state.showModal.edit} onHide={() => this.EditClose()} size="lg" fullscreen="lg-down" backdrop="static" keyboard={false} scrollable>
                    <Modal.Header className="modal-header-app" data-bs-theme="dark">
                        <Modal.Title className="w-100">{this.RenderEditHeader()}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <EditForm
                            ref={this.editForm}
                            cognitiveItemType={this.props.cognitiveItemType}
                            index={this.state.selectedIndex}
                            item={this.state.selectedItem}
                            existing={this.state.list}
                            onSave={(index, data) => this.SaveItem(index, data)}
                            onUpdate={(index, data) => this.UpdateItemInList(data)}
                            user={this.props.user}
                        />

                    </Modal.Body>
                    <Modal.Footer className="modal-footer-app">
                        <Button variant="secondary" onClick={() => this.EditClose()}>
                            Close
                        </Button>
                        {!this.props.user.IsGuest() && (
                            <Button variant="primary" onClick={() => this.EditSave()}>
                                Save Changes
                            </Button>
                        )}
                    </Modal.Footer>
                </Modal>

                {this.state.showModal.play && this.props.cognitiveItemType === "COGNITIVETEST" && (
                    <CognitiveItemModal
                        show={this.state.showModal.play}

                        code={this.state.selectedItem.code}
                        version={this.state.selectedItem.version}
                        category={this.state.selectedItem.category}
                        mode="PRACTICE"
                        inputType={this.props.device.IsTouchDevice() ? "TOUCH" : "KEYBOARD"}
                        showTargetProgress={true}

                        onSavedCognitiveItemResults={(cognitiveItemResults) => this.SavedCognitiveItemResults(cognitiveItemResults)}
                        onHide={() => this.PlayClose()}

                        device={this.props.device}
                        user={this.props.user}
                        theme={this.props.theme}
                    />
                )}

                {this.state.showModal.play && this.props.cognitiveItemType === "BRAINTRAINING" && (
                    <BrainTrainingModal
                        show={this.state.showModal.play}

                        code={this.state.selectedItem.code}
                        version={this.state.selectedItem.version}
                        category={this.state.selectedItem.category}
                        languageCode={this.state.selectedLanguage}
                        showTargetProgress={true}

                        onSavedCognitiveItemResults={(cognitiveItemResults) => this.SavedCognitiveItemResults(cognitiveItemResults)}
                        onHide={() => this.PlayClose()}

                        device={this.props.device}
                        user={this.props.user}
                        theme={this.props.theme}
                    />
                )}

                <ModalConfirm
                    ref={this.confirmModal}
                    show={this.state.showModal.confirm}
                    onCancel={() => this.DeleteClose()}
                    onConfirm={() => this.DeleteItem()}
                    title="Delete Cognitive Item"
                    message="Are you sure you want to delete this cognitive item?"
                    device={this.props.device}
                    user={this.props.user}
                    theme={this.props.theme}
                />

                <ModalConfirm
                    ref={this.confirmDevDeleteModal}
                    show={this.state.showModal.confirmDevDelete}
                    onCancel={() => this.DevDeleteClose()}
                    onConfirm={() => this.DevDeleteItem()}
                    title="Delete Cognitive Item"
                    message="Are you sure you want to delete this cognitive item?"
                    warning="<b>YOU ARE LOGGED IN WITH A DEVELOPER ACCOUNT.</b><br/>Any results associated with this cognitive item will also be deleted."
                    device={this.props.device}
                    user={this.props.user}
                    theme={this.props.theme}
                />

            </div>
        );
    }

    RenderCognitiveItems() {
        let rows = this.TabulateData();
        if (this.props.device.ShowMobileUI()) {

            return (
                <div>
                    <Table responsive className="transparent-table m-0" variant={this.props.theme.GetPlayBackgroundVariant()}>
                        <tbody>
                            {this.RenderDashboardTableRows(rows)}
                        </tbody>
                    </Table>
                </div>
            );

        } else {

            return (
                <div>
                    <div className="d-flex pt-4">
                        <TablePagination
                            canSearch
                            canChangePageResults
                            dataQuery={this.state.dataQuery}
                            onUpdated={(query) => this.setState({ dataQuery: query }, () => { this.GetData(); })}
                            user={this.props.user}
                            device={this.props.device}
                            theme={this.props.theme}
                        />
                        {!this.props.user.IsDeveloper() && (
                            <span></span>
                        )}
                        {this.props.user.IsDeveloper() && (
                            <span className="mt-auto">
                                <ButtonAdd onClick={() => this.EditOpen()} text={this.props.cognitiveItemType === "COGNITIVETEST" ? "New Cognitive Test" : "New Brain Training Game"} />
                            </span>
                        )}
                    </div>
                    <Table responsive className="noEndBorder transparent-table" variant={this.props.theme.GetPlayBackgroundVariant()}>
                        <tbody>
                            {this.RenderFullWidthTableRows(rows)}
                        </tbody>
                    </Table>
                </div>
            );

        }
    }

    RenderDashboardTableRows(rows) {
        let tableRows = [];
        rows.forEach((_, index) => {
            tableRows.push(
                <tr key={`ROW-${index}`}>
                    <Card as="td" className="clickable-card m-1">
                        <Card.Body className="p-2" onClick={() => this.PlayOpen(index, this.state.data[index])}>
                            <Card.Text as="div">
                                <div className="d-flex justify-content-between">
                                    <div className="pt-1">
                                        {this.state.data[index].text && !this.validate.IsEmpty(this.state.data[index].text.text) && (
                                            <span dangerouslySetInnerHTML={{ __html: this.state.data[index].text.text }}></span>
                                        )}
                                        {(!this.state.data[index].text || this.validate.IsEmpty(this.state.data[index].text.text)) && (
                                            <span dangerouslySetInnerHTML={{ __html: this.state.data[index].code }}></span>
                                        )}
                                        &nbsp;v<span dangerouslySetInnerHTML={{ __html: this.state.data[index].version }}></span>
                                    </div>
                                    <ButtonStatus size="sm" status={this.state.data[index].status} />
                                </div>
                                {this.state.data[index].text && this.state.data[index].text.description && (
                                    <div>
                                        <hr/>
                                        <span dangerouslySetInnerHTML={{ __html: this.state.data[index].text.description }}></span>
                                    </div>
                                )}
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </tr>
            );
        })
        return tableRows;
    }

    RenderFullWidthTableRows(rows) {
        let tableRows = [];
        rows.forEach((_, index) => {
            tableRows.push(
                <tr key={`ROW-${index}`}>
                    {this.state.fields.map((item, subindex) => (
                        rows[index][item.key] === 'Actions' ?
                            this.RowActions(index, subindex, this.props.user.Role()) :
                            rows[index][item.key] === 'Status' ?
                                <td key={`STATUS-${subindex}`} className="pt-4">
                                    <ButtonStatus status={this.state.data[index].status} />
                                </td> :
                                <td key={`KEY-${subindex}`} className={item.className}>
                                    <div><span className="miniLabel">{item.label}:</span></div>
                                    <span dangerouslySetInnerHTML={{ __html: rows[index][item.key] }}></span>
                                </td>
                    ))}
                </tr>
            );
        })
        return tableRows;
    }

    RowActions(index, subindex, role) {
        if (role === 'Developer') {
            return (
                <td key={`ACTION-${subindex}`} className="text-end text-nowrap pt-4">
                    <ButtonDelete onClick={() => this.DevDeleteOpen(index, this.state.data[index])} />&nbsp;
                    <ButtonEdit onClick={() => this.EditOpen(index, this.state.data[index])} />&nbsp;
                    <ButtonPlay onClick={() => this.PlayOpen(index, this.state.data[index])} />
                </td>
            )
        } else if (role === 'Admin') {
            return (
                <td key={`ACTION-${subindex}`} className="text-end text-nowrap pt-4">
                    {this.state.data[index].numberOfResults === 0 && (
                        <span>
                            <ButtonDelete onClick={() => this.DeleteOpen(index, this.state.data[index])} />&nbsp;
                        </span>
                    )}
                    <ButtonEdit onClick={() => this.EditOpen(index, this.state.data[index])} />&nbsp;
                    <ButtonPlay onClick={() => this.PlayOpen(index, this.state.data[index])} />
                </td>
            )
        } else {
            return (
                <td key={`ACTION-${subindex}`} className="text-end text-nowrap pt-4">
                    <ButtonPlay onClick={() => this.PlayOpen(index, this.state.data[index])} />
                </td>
            )
        }
    }

    RenderEditHeader() {
        return (
            <div className="d-flex justify-content-between">
                <div className="text-start">{this.GetSelectedName()}</div>
                <div className="text-end">
                    <StatusSelect status={this.state.selectedItem.status} onUpdated={(status) => { this.SetItemStatus(status) } } />
                </div>
            </div>
        )
    }

}
